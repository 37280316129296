import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  assetComp:boolean=true;
  payrollComp:boolean=false;
  creditorComp:boolean=false;
  debtorComp:boolean=false;
  expenditureComp:boolean=false;
  incomeComp: boolean=false;
  procurementComp:boolean=false;  

  assets(){
      this.assetComp=true,
      this.payrollComp=false;
      this.creditorComp=false;
      this.debtorComp=false;
      this.expenditureComp=false;
      this.incomeComp=false;
      this.procurementComp=false;
  }

  payroll(){
    this.assetComp=false,
    this.payrollComp=true;
    this.creditorComp=false;
    this.debtorComp=false;
    this.expenditureComp=false;
    this.incomeComp=false;
    this.procurementComp=false;
  }

  creditor(){
    this.assetComp=false,
    this.payrollComp=false;
    this.creditorComp=true;
    this.debtorComp=false;
    this.expenditureComp=false;
    this.incomeComp=false;
    this.procurementComp=false;
  }

  debtors(){
    this.assetComp=false,
      this.payrollComp=false;
      this.creditorComp=false;
      this.debtorComp=true;
      this.expenditureComp=false;
      this.incomeComp=false;
      this.procurementComp=false;
  }
  expenditure(){
    this.assetComp=false,
    this.payrollComp=false;
    this.creditorComp=false;
    this.debtorComp=false;
    this.expenditureComp=true;
    this.incomeComp=false;
    this.procurementComp=false;
  }

  income(){
    this.assetComp=false,
    this.payrollComp=false;
    this.creditorComp=false;
    this.debtorComp=false;
    this.expenditureComp=false;
    this.incomeComp=true;
    this.procurementComp=false;
  }

  procurement(){
    this.assetComp=false,
    this.payrollComp=false;
    this.creditorComp=false;
    this.debtorComp=false;
    this.expenditureComp=false;
    this.incomeComp=false;
    this.procurementComp=true;
  }

}
