import { AnnouncementComponent } from './dashboard/announcement/announcement.component';
import { CreateMemoComponent } from './dashboard/create-memo/create-memo.component';
import { DepartmentsComponent } from './dashboard/departments/departments.component';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { MainPagesComponent } from './layouts/main-pages/main-pages.component';
import { GuestGuard } from './Middleware/guest.guard';
import { AuthGuard } from './Middleware/auth.guard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './main-pages/home/home.component';
import { DashboardhomeComponent } from './dashboard/dashboardhome/dashboardhome.component';
import { DashboardbudgetsComponent } from './dashboard/dashboardbudgets/dashboardbudgets.component';
import { DashboardrequestsComponent } from './dashboard/dashboardrequests/dashboardrequests.component';
import { OperationComponent } from './dashboard/operation/operation.component';
import { HrManagementComponent } from './dashboard/hr-management/hr-management.component';
import { FinanceComponent } from './dashboard/finance/finance.component';
import { FilemanagementComponent } from './dashboard/filemanagement/filemanagement.component';
import { SettingsComponent } from './dashboard/settings/settings.component';
import { StaffdetailsComponent } from './dashboard/hr-management/staff/staffdetails/staffdetails.component';
import { MessagesComponent } from './dashboard/messages/messages.component';



const routes: Routes = [
  
 
// STAFF MANAGER OPEN ROUTE
  
  {
    path: '',
    component:  HomeComponent,
    // canActivate: [ GuestGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      
      {
      
      path: 'home',
      component: HomeComponent,
     },
  ]
  },

  // STAFF MANAGER SECURE ROUTE

  {
    path: 'dashboard',
    component:  DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      
     {
      
      path: 'home',
      component: DashboardhomeComponent,
     },
     {
     path: 'departments',
      component: DepartmentsComponent,
     },
     {
      path: 'operations',
       component: OperationComponent,
      },
      {
        path: 'hr-mgt',
         component: HrManagementComponent,
        },
     {
      
      path: 'budgets',
      component: DashboardbudgetsComponent,
     },
     {
      path: 'requests',
      component: DashboardrequestsComponent,
     },

     {
      path: 'finance',
      component: FinanceComponent,
     },

     {
      path: 'file-mgt',
      component: FilemanagementComponent,
     },

     {
      path: 'settings',
      component: SettingsComponent,
     },
     
     {
      path: 'staff-profile',
      component: StaffdetailsComponent,
     },

     {
      path: 'message',
      component: MessagesComponent,
     },

     {
      path: 'memo',
      component: CreateMemoComponent,
     },

     {
      path: 'announcement',
      component: AnnouncementComponent,
     },


   
   

  ]
  },
];

@NgModule({
  imports: [CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })],
  //   RouterModule.forRoot(routes)],
    // exports: [RouterModule]
    //     useHash: false
  exports: [RouterModule]
})
export class AppRoutingModule { }
