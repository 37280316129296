import { Staff } from '../../models/hr-mgt.model';


export class AddStaff {
    static readonly type = '[Staff] Add';

    constructor(public payload: Staff) {
    }
}

export class GetStaff {
    static readonly type = '[Staff] Get';
}