import { Faculty } from './../../models/settings.model';


export class AddFaculty {
    static readonly type = '[Faculty] Add';

    constructor(public payload: Faculty) {
    }
}

export class GetFaculty {
    static readonly type = '[Faculty] Get';
}


export class DeleteFaculty {
    static readonly type = '[Faculty] Delete';

    constructor(public _id: number) {
    }
}


export class UpdateFaculty {
    static readonly type = '[Faculty] Update';

    constructor(public payload: Faculty, public _id: number) {
    }
}