import { Events } from './../models/operations.model';


export class AddEvent {
    static readonly type = '[Event] Add';

    constructor(public payload: Events) {
    }
}

export class GetEvent {
    static readonly type = '[Event] Get';
}
