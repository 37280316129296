import { Report } from './../models/operations.model';


export class AddReport {
    static readonly type = '[Report] Add';

    constructor(public payload: Report) {
    }
}

export class GetReport {
    static readonly type = '[Report] Get';
}
