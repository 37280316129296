<div class="container">
    <div class="card" style="margin-top:20px">
        <div style="display:flex; justify-content: space-between; padding:20px">
            <div style="display: flex;">
                <div>
                    <div style="color:#00356B; font-size:16px; height:70%; padding-right:8px; font-weight:500">REPLY</div>
                </div>
            </div>
            
            <div style="display: flex;">
                <div><i style="color:#B5861E; background-color: #EDEDEE; padding:8px; border-radius: 20px;margin-right: 5px;" class="far fa-trash-alt"></i></div>
                <div><i style="color:#B5861E; background-color: #EDEDEE; padding:8px; border-radius: 20px;margin-right: 5px;" class="far fa-trash-alt"></i></div>
                <div><i style="color:#B5861E; background-color: #EDEDEE; padding:8px; border-radius: 20px;" class="far fa-trash-alt"></i></div>
            </div>
        </div>
        <div class="b-reply" style="font-weight: 500;color:#00356B; font-size:14px; font-weight:600">From: <input type="text"></div>
        <div class="b-reply" style="font-weight: 500;color:#00356B; font-size:14px; font-weight:600">To: <input type="text"></div>


        <div class="mail-message">
           <textarea name="" id="" cols="70" placeholder="Type message here" rows="8"></textarea>
        </div>

        <div class="file-u" style="display: flex; justify-content: space-between; margin: 0px 20px">
            <div style="display: flex;">
                <div><img src="assets/images/file uploader.svg"></div>
                <div>Attach File</div>
            </div>
            <div class="mid-tab">
                <button type="button" class="btn btn">Create</button>
            </div>
        </div>
    </div>
</div>