import { SetTimeLimitModalComponent } from 'src/app/dashboard/hr-management/set-time-limit-modal/set-time-limit-modal.component'
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {

  title = "Attendance"

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  setTime(){
    this.dialog.open(SetTimeLimitModalComponent, {height:'250px',width:'500px'});
  }

}
