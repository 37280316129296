import { GetDepartment } from './../../../shared/actions/settings/depatments.action';
import { GetStaff } from './../../../shared/actions/hr-mgt/staff.action';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddstaffmodalComponent } from '../addstaffmodal/addstaffmodal.component';



@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

  title = "Staff"
  staffs$!: any;
  allStaffs: any;

  constructor(
    public dialog: MatDialog, 
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new (GetStaff))
    this.staffs$ = this.store.select(state => state.staffs.staffs).subscribe(data =>{
      this.allStaffs = data
      // console.log(this.allStaffs)
    });
  }

  addStaffs(){
  this.dialog.open(AddstaffmodalComponent, {height:'580px',width:'500px'});
}


  profile(){
    console.log("hello")
    this.router.navigate(['dashboard/staff-profile'])
  }

}
