<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder" >
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls">  
        <div class="row">
            <div class="col-md-3">
                <input id="input-field" placeholder="Search"  type="text">
            </div>
            <div class="col-md-2" style="margin-right: 10px;">
               <input id="input-field" type="date">            
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
                <input id="input-field" type="date" >   
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2" (click)="addEvents()" id="c-btn">
                <button id="createBtn" style="font-size:13px; color:#fff; background-color:#B5861E" class="btn btn" >Add New</button>
            </div>
        </div>
    </section>

    <div class="card" id="t-design">
        <div class="table-responsive">
            <table class="table">
                <thead>
                  <tr id='t-head'>
                    <td scope="col">ID</td>
                    <td scope="col">Event Name</td>
                    <td scope="col">Description</td>
                    <td scope="col">Location</td>
                    <td scope="col">Start Date</td>
                    <td scope="col">End Date</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="allEvents.length">
                        <tr *ngFor="let event of allEvents let i = index" >
                            <td scope="row">{{ i + 1 }}</td>
                            <td>{{event.name}}</td>
                            <td>{{event.description}}</td>
                            <td>{{event.location}}</td>
                            <td>{{event.start_date}}</td>
                            <td>{{event.end_date | date}}</td>
                            <td></td>
                        </tr>
                    </ng-container>
                </tbody>
              </table>
        </div>
        <div>
            <ngx-skeleton-loader [theme]="{'height.px': 50}" count="10" appearance="line"  *ngIf="!allEvents.length"></ngx-skeleton-loader>
        </div>
      </div>

</div>    
