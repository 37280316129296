import { Bonus } from './../../models/hr-mgt.model';
import { GetStaff } from './../../actions/hr-mgt/staff.action';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable, isDevMode } from '@angular/core';
import { Staff } from '../../models/hr-mgt.model';
import { Time } from '../../models/hr-mgt.model';



let Url = ""
if (isDevMode()) {
  Url = environment.endpoint;
} else {
  console.log('💪 Production!');
}

@Injectable({
  providedIn: 'root'
})
export class HrService {

  APIUrl =  Url


  constructor(
    private http: HttpClient, 
  ) { }


  createStaff(staff: Staff){
    return this.http.post<any>(`${this.APIUrl}/api/admin/staff/create`, staff)
  }
  
  getStaff(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/staff`)
  }

  setTime(time: Time){
    return this.http.post<any>(`${this.APIUrl}/api/admin/time-limit/create`, time)
  }

  createBonus(bonus: Bonus){
    return this.http.post<any>(`${this.APIUrl}/api/admin/benefit/create`, bonus)
  }

  getBonus(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/benefit`)
  }

  deleteBonus(id: any) {
    return this.http.delete(`${this.APIUrl}/api/admin/benefit/` + id + `/delete`);
}

updateBonus(bonus: Bonus, id: any) {
  return this.http.put<any>(`${this.APIUrl}/api/admin/benefit/` + id + `/update`, bonus);
}
  
}
