import { MatDialogRef } from '@angular/material/dialog';
import { Department } from './../../../../shared/models/settings.model';
import { UpdateDepartment } from './../../../../shared/actions/settings/depatments.action';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-updatemodal',
  templateUrl: './updatemodal.component.html',
  styleUrls: ['./updatemodal.component.scss']
})
export class UpdatemodalComponent implements OnInit {

  departmentForm!: FormGroup;
  selectedDepartment: any;
  selectedHeadOfDepartment: any;
  departmentValue! : any;
  HodValue!: any
  facultyId: any


  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<UpdatemodalComponent>

  ) { }

  ngOnInit() {
    this.departmentForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      facultyID: new FormControl(null, []),
      head_of_dept: new FormControl(null, []),
    });
  }

  get input(){
    return this.departmentForm.controls;
  }

  editDepartment(){
    this.facultyId = localStorage.getItem("facultyID")
    console.log(this.facultyId)
    this.store.dispatch(new UpdateDepartment(this.departmentForm.value, this.facultyId));
    this.dialogRef.close()
  }


    // getting staff department value
    selectDepartment(val:any){
      this.departmentFunction(val)
    }
          
      departmentFunction(val:any){
        this.departmentValue = val;
  
    }
  
      // getting head of department value
      selectHeadOfDepartment(val:any){
        this.HodFunction(val)
      }
            
      HodFunction(val:any){
        this.HodValue = val;

      }

}
