<div class="container">
    <div class="card" style="margin-top:20px">
        <div style="display:flex; justify-content: space-between; padding:20px">
            <div style="display: flex;">
                <div>
                    <div style="color:#00356B; font-size:13px; border-right: 1px solid #7A7A7A; height:70%; padding-right:8px; font-weight:600">Prof. Madu C.</div>
                    <div style="font-weight: 500;color:#A5A5A5">To:Me</div>
                </div>
                <div style="color:#00356B; font-size: 10px;padding-left:8px;font-weight: 500;padding-top:2px">maduchimk@gmail.com</div>
            </div>

            <div style="display: flex;">
                <div style="font-size: 10px; color:#7A7A7A; padding-top:4px;padding-right:5px">12 hours ago</div>
                <div><i style="color:#B5861E; background-color: #EDEDEE; padding:8px; border-radius: 20px;" class="far fa-trash-alt"></i></div>
            </div>
        </div>
        <div class="b-reply">
            <div class="reply">Reply</div>
        </div>

        <div class="mail-message">
            <p>Hello sir</p>
            <p>Find the attached document you previously requested</p>
            <p>Thanks</p>
            <p style="padding-top:15px">Prof. Madu C</p>
            <p>Computer Department</p>

        </div>
    </div>
</div>