import { BonusState } from './shared/state/hr-mgt/bonus.state';
import { facultyState } from './shared/state/settings/faculty.state';
import { TimeState } from './shared/state/hr-mgt/attendance.state';
import { StaffState } from './shared/state/hr-mgt/staff.state';
import { budgetState } from './shared/state/budget.state';
import { RequestState } from './shared/state/requests.state';
import { EventState } from './shared/state/events.state';
import { StockState } from './shared/state/stock.state';
import { ReportState } from './shared/state/works.state';
import { announceState } from './shared/state/announce.state';
import { InformationComponent } from 'src/app/dashboard/hr-management/staff/staffs-components/information/information.component';
import { DashboardModule } from './layouts/dashboard/dashboard.module';
import { MainPagesModule } from './layouts/main-pages/main-pages.module';

import { GuestGuard } from './Middleware/guest.guard';
import { AuthGuard } from './Middleware/auth.guard';
import { AuthService } from './shared/services/auth/auth.service';

import {NgxsModule} from '@ngxs/store';
import { NgxsStoragePluginModule} from '@ngxs/storage-plugin'
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';




import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
// import { HttpClientModule } from '@angular/common/http';

import { MDBBootstrapModule } from 'angular-bootstrap-md';


import { TokenInterceptorService } from './shared/services/auth/token-interceptor.service';
import { AuthState } from './shared/state/auth.state';
import { OperationComponent } from './dashboard/operation/operation.component';
import { ReportComponent } from './dashboard/operation/operationComponent/report/report.component';
import { EventsComponent } from './dashboard/operation/operationComponent/events/events.component';
import { StockComponent } from './dashboard/operation/operationComponent/stock/stock.component';
import { TripComponent } from './dashboard/operation/operationComponent/trip/trip.component';
import { RequestComponent } from './dashboard/operation/operationComponent/request/request.component';
import { BudgetComponent } from './dashboard/operation/operationComponent/budget/budget.component';
import { EventModalComponent } from './dashboard/operation/operationComponent/event-modal/event-modal.component';
import { HrManagementComponent } from './dashboard/hr-management/hr-management.component';
import { StaffComponent } from 'src/app/dashboard/hr-management/staff/staff.component';
import { BonusComponent } from 'src/app/dashboard/hr-management/bonus/bonus.component';
import { AttendanceComponent } from 'src/app/dashboard/hr-management/attendance/attendance.component';
import { RecruitmentComponent } from 'src/app/dashboard/hr-management/recruitment/recruitment.component';
import { AddstaffmodalComponent } from 'src/app/dashboard/hr-management/addstaffmodal/addstaffmodal.component';
import { LeaveComponent } from './dashboard/operation/operationComponent/leave/leave.component';
import { FinanceComponent } from './dashboard/finance/finance.component';
import { AsstesComponent } from './dashboard/finance/asstes/asstes.component';
import { PayrollComponent } from './dashboard/finance/payroll/payroll.component';
import { CreditorComponent } from './dashboard/finance/creditor/creditor.component';
import { DebtorComponent } from './dashboard/finance/debtor/debtor.component';
import { ExpemditureComponent } from './dashboard/finance/expemditure/expemditure.component';
import { IncomeComponent } from './dashboard/finance/income/income.component';
import { ProcurementComponent } from './dashboard/finance/procurement/procurement.component';
import { FilemanagementComponent } from './dashboard/filemanagement/filemanagement.component';
import { SettingsComponent } from './dashboard/settings/settings.component';
import { AchievementsComponent } from './dashboard/hr-management/staff/staffs-components/achievements/achievements.component';
import { PromotionComponent } from './dashboard/hr-management/staff/staffs-components/promotion/promotion.component';
import { DocumentComponent } from './dashboard/hr-management/staff/staffs-components/document/document.component';
import { SalaryComponent } from './dashboard/hr-management/staff/staffs-components/salary/salary.component';
import { BankdetailsComponent } from './dashboard/hr-management/staff/staffs-components/bankdetails/bankdetails.component';
import { RetirementComponent } from './dashboard/hr-management/staff/staffs-components/retirement/retirement.component';
import { StaffdetailsComponent } from './dashboard/hr-management/staff/staffdetails/staffdetails.component';
import { EducationComponent } from './dashboard/hr-management/staff/staffs-components/education/education.component';
import { MessagesComponent } from './dashboard/messages/messages.component';
import { StructureComponent } from './dashboard/settings/structure/structure.component';
import { FacultyComponent } from './dashboard/settings/faculty/faculty.component';
import { DepartmentComponent } from './dashboard/settings/department/department.component';
import { PositionsComponent } from './dashboard/settings/positions/positions.component';
import { RolesComponent } from './dashboard/settings/roles/roles.component';
import { ProfilemodalComponent } from './dashboard/settings/profilemodal/profilemodal.component';
import { FacultymodalComponent } from './dashboard/settings/facultymodal/facultymodal.component';
import { DepartmentmodalComponent } from './dashboard/settings/departmentmodal/departmentmodal.component';
import { PositionmodalComponent } from './dashboard/settings/positionmodal/positionmodal.component';
import { MailComponent } from './dashboard/messages/mail/mail.component';
import { ChatComponent } from './dashboard/messages/chat/chat.component';
import { MailmessageComponent } from './dashboard/messages/mail/mailmessage/mailmessage.component';
import { MailreplyComponent } from './dashboard/messages/mail/mailreply/mailreply.component';
import { CreateMemoComponent } from './dashboard/create-memo/create-memo.component';
import { MemoState } from './shared/state/memo.state';
import { AnnouncementComponent } from './dashboard/announcement/announcement.component';
import { NewMessageModalComponent } from './dashboard/messages/new-message-modal/new-message-modal.component';
import { AddStockModalComponent } from './dashboard/operation/operationComponent/add-stock-modal/add-stock-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { NewbenfitsComponent } from './dashboard/hr-management/newbenfits/newbenfits.component';
import { SetTimeLimitModalComponent } from './dashboard/hr-management/set-time-limit-modal/set-time-limit-modal.component';
import { departmentState } from './shared/state/settings/departments.state';
import { UpdatemodalComponent } from './dashboard/settings/departmentmodal/updatemodal/updatemodal.component';
import { FacultyupdatemodalComponent } from './dashboard/settings/facultymodal/facultyupdatemodal/facultyupdatemodal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';








@NgModule({
  declarations: [
    AppComponent,
    OperationComponent,
    ReportComponent,
    EventsComponent,
    StockComponent,
    TripComponent,
    RequestComponent,
    BudgetComponent,
    EventModalComponent,
    HrManagementComponent,
    StaffComponent,
    BonusComponent,
    AttendanceComponent,
    RecruitmentComponent,
    AddstaffmodalComponent,
    LeaveComponent,
    FinanceComponent,
    AsstesComponent,
    PayrollComponent,
    CreditorComponent,
    DebtorComponent,
    ExpemditureComponent,
    IncomeComponent,
    ProcurementComponent,
    FilemanagementComponent,
    SettingsComponent,
    StaffdetailsComponent,
    AchievementsComponent,
    PromotionComponent,
    DocumentComponent,
    SalaryComponent,
    BankdetailsComponent,
    RetirementComponent,
    InformationComponent,
    EducationComponent,
    MessagesComponent,
    StructureComponent,
    FacultyComponent,
    DepartmentComponent,
    PositionsComponent,
    RolesComponent,
    ProfilemodalComponent,
    FacultymodalComponent,
    DepartmentmodalComponent,
    PositionmodalComponent,
    MailComponent,
    ChatComponent,
    MailmessageComponent,
    MailreplyComponent,
    CreateMemoComponent,
    AnnouncementComponent,
    NewMessageModalComponent,
    AddStockModalComponent,
    NewbenfitsComponent,
    SetTimeLimitModalComponent,
    UpdatemodalComponent,
    FacultyupdatemodalComponent
  ],
  imports: [
    BrowserModule,
    NgxsModule.forRoot([
      AuthState, MemoState,
      announceState,
      ReportState,
      StockState,
      EventState,
      RequestState,
      budgetState,
      StaffState,
      TimeState,
      facultyState,
      departmentState,
      BonusState
        
    
    ]),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'auth._id', 'auth.message']
    }),
    HttpClientModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    MainPagesModule,
    ToastrModule.forRoot({
      toastClass: 'toast toast-bootstrap-compatibility-fix'
    }),
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    NgxSkeletonLoaderModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    HttpClientModule,

  ],
  providers: [
    FormsModule,
    ReactiveFormsModule,
   
    
    AuthService,
    AuthGuard, 
    GuestGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }     
  ],
  
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
