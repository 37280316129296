import { GetMemos } from './../../shared/actions/memo.action';
import { Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeesTasks, Feeds, OnlineEmployees } from 'src/app/shared/models/feeds.model';

@Component({
  selector: 'app-dashboardhome',
  templateUrl: './dashboardhome.component.html',
  styleUrls: ['./dashboardhome.component.scss']
})
export class DashboardhomeComponent implements OnInit {
  //icons

  memos$!: any
  allMemos! : any
  plusCircle: string = "plus-circle";
  user: string = "user";
  city: string = "city";
  building: string = "building";
  firstOrder : string = "first-order-alt";
  stickyNote: string = "sticky-note";
  calendar: string = "calendar";
  caretRight: string = "caret-right";

  feedsData: Feeds[] = [
    {
      title: 'Internal Memo',
      addressedBy: 'By HOD Finance Department',
      dateCreated: new Date(),
      messageTitle: 'MESSAGE TO ALL DEPARTMENT HEADS AND LECTURERS',
      addressedTo: 'To: All Departments',
      message: 'This is an important messsge, All department heads, Colleagues and Lecturers are to converge at the Lecture Theatre for a general briefing on the subject matter',
      image: 'assets/images/mission.jpeg'
    },
    {
      title: 'Admin',
      addressedBy: 'To All Department',
      dateCreated: new Date(),
      messageTitle: 'MESSAGE TO ALL DEPARTMENT HEADS AND LECTURERS',
      addressedTo: 'To: All Departments',
      message: 'This is an important messsge, All department heads, Colleagues and Lecturers are to converge at the Lecture Theatre for a general briefing on the subject matter',
      image: 'assets/images/mission.jpeg'
    },
    {
      title: 'HOD Finance',
      addressedBy: 'By HOD Finance Department',
      dateCreated: new Date(),
      messageTitle: 'MESSAGE TO ALL DEPARTMENT HEADS AND LECTURERS',
      addressedTo: 'To: All Departments',
      message: 'This is an important messsge, All department heads, Colleagues and Lecturers are to converge at the Lecture Theatre for a general briefing on the subject matter',
      image: 'assets/images/mission.jpeg'

    },
    {
      title: 'Internal Memo',
      addressedBy: 'By HOD Finance Department',
      dateCreated: new Date(),
      messageTitle: 'MESSAGE TO ALL DEPARTMENT HEADS AND LECTURERS',
      addressedTo: 'To: All Departments',
      message: 'This is an important messsge, All department heads, Colleagues and Lecturers are to converge at the Lecture Theatre for a general briefing on the subject matter',
      image: 'assets/images/mission.jpeg'
    },
    {
      title: 'Internal Memo',
      addressedBy: 'By HOD Finance Department',
      dateCreated: new Date(),
      messageTitle: 'MESSAGE TO ALL DEPARTMENT HEADS AND LECTURERS',
      addressedTo: 'To: All Departments',
      message: 'This is an important messsge, All department heads, Colleagues and Lecturers are to converge at the Lecture Theatre for a general briefing on the subject matter',
      image: 'assets/images/mission.jpeg'

    },
    {
      title: 'Internal Memo',
      addressedBy: 'By HOD Finance Department',
      dateCreated: new Date(),
      messageTitle: 'MESSAGE TO ALL DEPARTMENT HEADS AND LECTURERS',
      addressedTo: 'To: All Departments',
      message: 'This is an important messsge, All department heads, Colleagues and Lecturers are to converge at the Lecture Theatre for a general briefing on the subject matter',
      image: 'assets/images/mission.jpeg'
    },
  ];


  onlineEmployees: OnlineEmployees[] = [
    {
      image: 'assets/images/mission.jpeg'
    },
    {
      image: 'assets/images/mission.jpeg'
    },
    {
      image: 'assets/images/mission.jpeg'
    },
    {
      image: 'assets/images/mission.jpeg'
    },
    {
      image: 'assets/images/mission.jpeg'
    },
    
  ]

  employeeTasks: EmployeesTasks[] = [
    {
      ongoingTasks: 4,
      completedTasks: 5,
      assistingTasks: 8,
      setByMeTasks: 8,
      followingTasks: 10
    }
  ]

  constructor(private router: Router,
    private store: Store,
    ) { }

  ngOnInit(): void {
    this.store.dispatch(new (GetMemos))
    this.memos$ = this.store.select(state => state.memos.memos).subscribe(data =>{
      this.allMemos = data
    });
  }  

  goToRequestPage(){
    this.router.navigate(['/dashboard/requests']);
  }

  goToBudgetsPage(){
    this.router.navigate(['/dashboard/budgets']);
  }


  goToMemoPage(){
    this.router.navigate(['/dashboard/memo']);
  }

  goToAnnouncement(){
    this.router.navigate(['/dashboard/announcement']);

  }


  viewAllEmployees(){
    //takes to the all employees page
  }
}
