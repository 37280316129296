<div class="container" style="padding-top:30px">
   
    <div class="card" style="padding:10px; margin-bottom:17px; cursor:pointer">
        <div class="controller" style="display:flex; justify-content: space-around;  color: #C4C4C4; font-weight:500">
            <div (click)="coyStructure()" [ngClass]="{'active': structure}">Company Structure</div>
            <div (click)="coyFaculty()" [ngClass]="{'active': faculty}">Faculty</div> 
            <div (click)="coyDepartment()" [ngClass]="{'active': department}">Department</div>
            <div (click)="coyPosition()" [ngClass]="{'active': position}">Positions</div>
            <div (click)="coyRole()" [ngClass]="{'active': role}">Roles</div>
        </div>
    </div>
</div>


<div *ngIf="structure">
    <app-structure></app-structure>
</div>
<div *ngIf="faculty">
    <app-faculty></app-faculty>
</div>
<div *ngIf="department">
    <app-department></app-department>
</div>
<div *ngIf="position">
    <app-positions></app-positions>
</div>

<div *ngIf="role">
    <app-roles></app-roles>
</div>

