<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder">
                <span>Finance/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls;" style="margin-bottom:10px">  
        <div class="row">
            <div class="col-md-4" id="search_box">
                <input style="padding:8px 2px; padding-left:20px; border:none" placeholder="Search"  type="text">         
            </div>
            <div class="col-md-2" id="date_filter">
                <input style="padding: 6px 2px; border:none" type="date">            
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Department</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <!-- <div class="col-md-2" style="text-align: center !important;">
                <button style="font-size:13px; padding:7px 30px; font-weight: 500; color:#00356B; background-color:#fff" class="btn btn" >Export</button>
            </div>
            <div class="col-md-2">
                <button style="font-size:13px; color:#fff; background-color:#B5861E" class="btn btn" >Create New</button>
            </div> -->
        </div>
    </section>


    <div class="card">
        <div class="table-responsive">
            <table class="table" style="color:#00356B">
                <thead  style="background-color: #00356B;">
                  <tr style="color:#fff; font-weight:500;">
                    <td scope="col">Employee Name</td>
                    <td scope="col">Acc Number</td>
                    <td scope="col">Bank</td>
                    <td scope="col">Monthly CTC</td>
                    <td scope="col">Addition</td>
                    <td scope="col">Deduction</td>
                    <td scope="col">Reimbursement</td>
                    <td scope="col">Gross Pay</td>
                    <td scope="col">...</td>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>In Use</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>
                      <div dropdown>
                        <a dropdownToggle mdbWavesEffect class="nav-link active" mdbWavesEffect>...</a> 
                        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" id="tray" role="menu">
                            <a class="dropdown-item waves-light" mdbWavesEffect>Edit</a>
                            <a class="dropdown-item waves-light" mdbWavesEffect>Export</a>
                            <a class="dropdown-item waves-light" mdbWavesEffect>Share to Mail</a>
                            <a class="dropdown-item waves-light" mdbWavesEffect>Execute</a>
                            <a class="dropdown-item waves-light" mdbWavesEffect>Delete</a>
                        </div>
                    </div>
                    </td>

                  </tr>
                  <tr>
                    <td>Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>In Use</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>...</td>
                   
                  </tr>
                  <tr>
                    <td>Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>In Use</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>...</td>

                   
                  </tr>
                </tbody>
              </table>
        </div>
      </div>

</div>    
