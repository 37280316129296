import { Stock, Events } from './../../models/operations.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable, isDevMode } from '@angular/core';


let Url = ""
if (isDevMode()) {
  Url = environment.endpoint;
} else {
  console.log('💪 Production!');
}

@Injectable({
  providedIn: 'root'
})


export class OperationService {

  APIUrl =  Url


  constructor(
    private http: HttpClient, 

  ) { }

  getReport(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/work-report`)
  }

  createStock(stock: Stock){
    return this.http.post<any>(`${this.APIUrl}/api/admin/stock-inventory/create`, stock)
  }

  
  getStock(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/stock-inventory`)
  }


  createEvents(event: Events){
    return this.http.post<any>(`${this.APIUrl}/api/admin/event-activities/create`, event)
  }

  getEvents(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/event-activities`)
  }


  getRequests(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/request`)
  }

  getBudgets(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/budget`)
  }






}
