import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Memo } from '../../models/memo.model';

import { environment } from 'src/environments/environment';


let Url = ""
if (isDevMode()) {
  Url = environment.endpoint;
} else {
  console.log('💪 Production!');
}

@Injectable({
  providedIn: 'root'
})

export class MemoService {

  APIUrl =  Url

  constructor(
    private http: HttpClient,
    public router: Router,
  ) { }


  createMemo(memo: Memo){
    return this.http.post<any>(`${this.APIUrl}/api/admin/memos/create`, memo)
  }

  getMemos(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/memos`)
  }

  deleteMemo(id: any) {
    return this.http.delete(`${this.APIUrl}/api/admin/memos/` + id + `/delete`);
}


}
