import { AddDepartment, GetDepartment, DeleteDepartment, UpdateDepartment } from './../../actions/settings/depatments.action';
import { SettingService } from './../../services/settings/setting.service';
import { Department } from './../../models/settings.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'


export class DepartmentStateModel {
    departments!: Department[];   
}

@State<DepartmentStateModel>({
    name: 'departments',
    defaults: {
        departments: [], 
 
    }
})

@Injectable()
export class departmentState {

    constructor(private settingService: SettingService) {
    }

    @Selector()
    static getDepartment(state: DepartmentStateModel) {
        return state.departments;
    }


    @Action(GetDepartment)
    getDepartment({getState, setState}: StateContext<DepartmentStateModel>) {
        return this.settingService.getDepartment().pipe(tap((result) => {
            const state = getState();
            setState({
                ...state,
                departments: result.data,
            });
        }));
    }


    
    @Action(AddDepartment)
    addDepartment({getState, patchState}: StateContext<DepartmentStateModel>, {payload}: AddDepartment) {
        return this.settingService.createDepartment(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                departments: [...state.departments, result.data]
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err.statusText
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }


    
    @Action(DeleteDepartment)
    deleteTodo({getState, setState}: StateContext<DepartmentStateModel>, {_id}: DeleteDepartment) {
        return this.settingService.deleteDepartment(_id).pipe(tap((result:any) => { 
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            } 
            const state = getState();
            const filteredArray = state.departments.filter(item => item._id !== result.data._id)
            setState({
                ...state,
                departments: filteredArray,
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }


    @Action(UpdateDepartment)
    updateDepartment({getState, setState}: StateContext<DepartmentStateModel>, {payload, _id}: UpdateDepartment) {
        return this.settingService.updateDepartment(payload, _id).pipe(tap((result) => {
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            } 
            const state = getState();
            const departmentList = [...state.departments];
            const departmentIndex = departmentList.findIndex(item => item._id === result.data._id);
            departmentList[departmentIndex] = result.data;
            setState({
                ...state,
                departments: departmentList,
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }


    alert = {
        title: <any> "",
        message: <any> "",
        icon: <any> "",
    }



    alertWithMessage(title:any,message:any,icon:any){
        Swal.fire({
        title: title,
        text: message,
        icon: icon,
        })
    }
}
