
<header>
    <app-dashboard-sidebar></app-dashboard-sidebar> 

<app-dashboard-navbar></app-dashboard-navbar>
</header>

<main>
    <router-outlet></router-outlet>
</main>

<app-dashboard-footer></app-dashboard-footer>