<div class="container">
    <section id="top-row">
        <div class="row">
            <div class="col-md-2 budget-holder" >
                <span>Budgets</span>
            </div>
            <!-- <button class="col-md-2 offset-8 ml-lg-auto budget-holder-request-button" >
                <span>Make Requests</span>
            </button> -->
        </div>
    </section>

    <section id="mid-inputs-controls">  
        <div class="row mb-4">
            <div class="col-md-2">
                <input style="padding: 6px 2px"  type="text">
            </div>
            <div class="col-md-2">
               <input style="padding: 6px 2px" type="date">            
            </div>
            <div class="col-md-2">
                <input style="padding: 6px 2px" type="date">   
            </div>
            <div class="col-md-3">
                <select class="form-select" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example">
                    <option value="2">Import</option>
                    <option value="3">Export</option>
                </select>
            </div>
        </div>
    </section>

    <section id="table-data">
        <!-- <div  class="mat-elevation-z8"> -->
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
          
              <!-- Request Title Column -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Request Title </th>
                <td mat-cell *matCellDef="let row"> {{row.title}} </td>
              </ng-container>

              <!-- Sender Column -->
              <ng-container matColumnDef="sender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sender </th>
                <td mat-cell *matCellDef="let row"> {{row.sender}} </td>
              </ng-container>

               <!-- Recipient Column -->
               <ng-container matColumnDef="recipient">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient </th>
                <td mat-cell *matCellDef="let row"> {{row.recipient}} </td>
              </ng-container>

               <!-- Department Column -->
               <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                <td mat-cell *matCellDef="let row"> {{row.department}} </td>
              </ng-container>

                 <!-- Date Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.date | date}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> status </th>
                    <td mat-cell *matCellDef="let row"> {{row.date | date}} </td>
                </ng-container>
          
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
              <!-- Row shown when there is no matching data. -->
              <!-- <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr> -->
            </table>
          
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          <!-- </div> -->
          
    </section>

</div>

<!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"> -->

