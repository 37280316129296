import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debtor',
  templateUrl: './debtor.component.html',
  styleUrls: ['./debtor.component.scss']
})
export class DebtorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
