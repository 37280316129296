import { Budget } from './../models/operations.model';


export class AddBudget {
    static readonly type = '[Budget] Add';

    constructor(public payload: Budget) {
    }
}

export class GetBudgets {
    static readonly type = '[Budget] Get';
}