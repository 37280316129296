import { Time } from '../../models/hr-mgt.model';


export class AddTime {
    static readonly type = '[Time] Add';

    constructor(public payload: Time) {
    }
}

