import { Announce } from './../models/announce.model';
import { AddAnnouncement, DeleteAnnouncement, GetAnnouncement, UpdateAnnouncement, SetSelectedAnnouncement } from './../actions/announcement.action';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import { AnnouncementService } from '../services/announce/announcement.service';
import Swal from 'sweetalert2'


export class AnnounceStateModel {
    announcements!: Announce[];   
}

@State<AnnounceStateModel>({
    name: 'announcements',
    defaults: {
        announcements: [], 
 
    }
})

@Injectable()
export class announceState {

    constructor(private announcementService: AnnouncementService) {
    }

    @Selector()
    static getAnnouncement(state: AnnounceStateModel) {
        return state.announcements;
    }


    @Action(AddAnnouncement)
    addTodo({getState, patchState}: StateContext<AnnounceStateModel>, {payload}: AddAnnouncement) {
        return this.announcementService.createAnnouncement(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                announcements: [...state.announcements, result.data]
            });
        }));
    }


    @Action(GetAnnouncement)
    getMemos({getState, setState}: StateContext<AnnounceStateModel>) {
        return this.announcementService.getAnnouncement().pipe(tap((result) => {
            const state = getState();
            setState({
                ...state,
                announcements: result.data,
            });
        }));
    }


    @Action(DeleteAnnouncement)
    DeleteAnnouncement({getState, setState}: StateContext<AnnounceStateModel>, {id}: DeleteAnnouncement) {
        return this.announcementService.deleteAnnouncent(id).pipe(tap((result:any) => { 
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            } 
            const state = getState();
            const filteredArray = state.announcements.filter(item => item._id !== result.data._id)
            setState({
                ...state,
                announcements: filteredArray,
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }



        
    alert = {
        title: <any> "",
        message: <any> "",
        icon: <any> "",
    }



    alertWithMessage(title:any,message:any,icon:any){
        Swal.fire({
        title: title,
        text: message,
        icon: icon,
        })
    }

}
