<div class="container" style="padding-top:30px">
   
    <div class="card" style="padding:10px; margin-bottom:17px; cursor:pointer">
        <div class="controller" style="display:flex; justify-content: space-around;  color: #C4C4C4; font-weight:500">
            <div id="common-controller" (click)="staffBtn()" [ngClass]="{'active': staff}">Staff</div>
            <div id="common-controller" (click)="bonusBtn()" [ngClass]="{'active': bonus}">Bonus & Benefits</div> 
            <div id="common-controller" (click)="attendanceBtn()" [ngClass]="{'active': attendance}">Attendance</div>
            <div id="common-controller" (click)="recruitBtn()" [ngClass]="{'active': recruit}">Recruitment</div>
        </div>
    </div>
</div>


<div *ngIf="staff">
    <app-staff></app-staff>
</div>
<div *ngIf="bonus">
    <app-bonus></app-bonus>
</div>
<div *ngIf="attendance">
    <app-attendance></app-attendance>
</div>
<div *ngIf="recruit">
    <app-recruitment></app-recruitment>
</div>

