import { Announce } from './../models/announce.model';


export class AddAnnouncement {
    static readonly type = '[Announce] Add';

    constructor(public payload: Announce) {
    }
}

export class GetAnnouncement {
    static readonly type = '[Announce] Get';
}

export class UpdateAnnouncement {
    static readonly type = '[Announce] Update';

    constructor(public payload: Announce, public id: number) {
    }
}

export class DeleteAnnouncement {
    static readonly type = '[Announce] Delete';

    constructor(public id: number) {
    }
}

export class SetSelectedAnnouncement {
    static readonly type = '[Announce] Set';

    constructor(public payload: Announce) {
    }
}