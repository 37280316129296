<div class="container" style="padding-top:30px">
   
    <div class="card" style="padding:10px; margin-bottom:17px; cursor:pointer">
        <div class="controller" style="display:flex; justify-content: space-around;  color: #C4C4C4; font-weight:500">
            <div (click)="assets()" [ngClass]="{'active': assetComp}">Assets</div>
            <div (click)="payroll()" [ngClass]="{'active': payrollComp}">Payroll</div> 
            <div (click)="creditor()" [ngClass]="{'active': creditorComp}">Creditors</div>
            <div (click)="debtors()" [ngClass]="{'active': debtorComp}">Debtors</div>
            <div (click)="expenditure()" [ngClass]="{'active': expenditureComp}">Expenditure</div>
            <div (click)="income()" [ngClass]="{'active': incomeComp}">Income</div>
            <div (click)="procurement()" [ngClass]="{'active': procurementComp}">Procurement</div>
        </div>
    </div>
</div>


<div *ngIf="assetComp">
    <app-asstes></app-asstes>
</div>
<div *ngIf="payrollComp">
    <app-payroll></app-payroll>
</div>
<div *ngIf="creditorComp">
    <app-creditor></app-creditor>
</div>
<div *ngIf="debtorComp">
    <app-debtor></app-debtor>
</div>
<div *ngIf="expenditureComp">
    <app-expemditure></app-expemditure>
</div>
<div *ngIf="incomeComp">
    <app-income></app-income>
</div>
<div *ngIf="procurementComp">
    <app-procurement></app-procurement>
</div>