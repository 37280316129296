import { UpdateFaculty } from './../../../../shared/actions/settings/faculty.action';
import { MatDialogRef } from '@angular/material/dialog';
import { FacultymodalComponent } from './../facultymodal.component';
import { Store } from '@ngxs/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facultyupdatemodal',
  templateUrl: './facultyupdatemodal.component.html',
  styleUrls: ['./facultyupdatemodal.component.scss']
})
export class FacultyupdatemodalComponent implements OnInit {

  facultyForm! : FormGroup
  selectedDepartment: any;
  selectedHeadOfDepartment: any;
  facultyValue! : any
  facultyId! : any
  data: any

  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<FacultyupdatemodalComponent>

  ) { }

  ngOnInit() {
    this.facultyForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      deanID: new FormControl(null, []),
      // head_of_dept: new FormControl(null, []),
    });
  }

  get input(){
    return this.facultyForm.controls;
  }

    editFaculty(){

      const { name, deanID } = this.facultyForm.value

      this.data = {
        name,
        deanID: this.facultyValue
      }
      this.facultyId = localStorage.getItem("facultyID")
      console.log(this.facultyId)
      this.store.dispatch(new UpdateFaculty(this.data , this.facultyId));
      this.dialogRef.close()
    }
  

     // getting dean id value
     selectDepartment(val:any){
      this.departmentFunction(val)
    }
          
      departmentFunction(val:any){
        this.facultyValue = val;
        console.log(this.facultyValue)
  
    }
}
