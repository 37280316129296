import { Message } from './../../models/message';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';


let Url = ""
if (isDevMode()) {
  Url = environment.endpoint;
} else {
  console.log('💪 Production!');
}


@Injectable({
  providedIn: 'root'
})

export class MessageService {

  APIUrl =  Url

  constructor(    
    private http: HttpClient,
    public router: Router) { }

    
  createMessage(message: Message){
    return this.http.post<any>(`${this.APIUrl}/api/admin/messages/create`, message)
  }

}
