import { AnnouncementComponent } from './../../../dashboard/announcement/announcement.component';
import { Announce } from './../../models/announce.model';
import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


let Url = ""
if (isDevMode()) {
  Url = environment.endpoint;
} else {
  console.log('💪 Production!');
}

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  APIUrl =  Url


  constructor(
    private http: HttpClient,
    public router: Router,
  ) { }

  createAnnouncement(announce: Announce){
    return this.http.post<any>(`${this.APIUrl}/api/admin/announcement/create`, announce)
  }

  getAnnouncement(){
    return this.http.get<any>(`${this.APIUrl}/api/admin/announcement`)
  }

  deleteAnnouncent(id: any) {
    return this.http.delete(`${this.APIUrl}/api/admin/announcement/` + id + `/delete`);
}



  
}
