<div class="container" style="margin:40px 0px">
    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Bank Name</label>
                <input type="text" class="form-control" />
            </div>  
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Account Name</label>
                <input type="text" class="form-control" />
            </div>  
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Bank Number</label>
                <input type="text" class="form-control" />
            </div>  
        </div>
    </div>
</div>