<!--Main Navigation-->
<!-- <div class="nav justify-content-center white lighten-4 top-nav mr-1">
    hello
</div> -->
 
<div class="nav white lighten-4 top-nav mr-1" id="nav-header">

    <div class="nav header-menu">
        <span (click)="toggleSideNav()" class="menu-icon material-icons">menu</span>
    </div> 

    <div class="h-container" style="display:flex; justify-content: space-evenly;">
        <div class="header-name">Dashboard</div>
        <div class="header-search"><input type="text"></div>
    </div>

    
    <div class="h-container"  style="display: flex;">
        <div class="date"><img src="assets/images/time.png">29th march</div>
        <div class="mgt">Management</div>
        <div class="mgt-img"><img src="assets/images/notification.png"></div>
    </div>




    <div class="nav-item" dropdown>
        <a dropdownToggle mdbWavesEffect class="nav-link active" mdbWavesEffect>
            Hi, user <img src="assets/images/logo/mu-logo.png" style="width: 50px; height: 50px;" class="img-fluid" alt=""></a> 
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="[ '/dashboard-transcript/profile']">Profile</a>
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="[ '/dashboard-transcript/settings']">settings</a>
           
            <div class="divider dropdown-divider"></div>
            <a class="dropdown-item waves-light" mdbWavesEffect type="button" (click)="LogOut()">Logout</a>
        </div>
    </div>
    
</div>




<!--Main Navigation-->