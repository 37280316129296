<div mat-dialog-title style="display: flex; justify-content: space-between;border-bottom: 1px solid gray;">
    <div style="font-size: 20px; color:#00356B; font-weight: 500; ">Add Benefit</div>
</div>

<mat-dialog-content>
    <form [formGroup]="addBenefit">
    <div style="width:100%">

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Name</label>
            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && addBenefit.get('name')?.invalid}"/>
            <div *ngIf="submitted && addBenefit.get('name')?.invalid" class="invalid-feedback">
                <div *ngIf="addBenefit.get('name')?.hasError('required')">Name is required</div>
            </div>
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label" style="color:#00356B">Select Department</label>
            <select style="padding:10px" class="form-select form-select-sm" aria-label=".form-select-sm example" formControlName="department" [(ngModel)]="selectedDepartment" (ngModelChange)="selectDepartment($event)">
                <option value="">Select Department</option>
                <option *ngFor="let department of allDepartments" [value]="department._id">{{department.name}}</option>
            </select>
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label" style="color:#00356B">Staff</label>
            <select style="padding:10px" class="form-select form-select-sm" aria-label=".form-select-sm example" formControlName="staff">
                <option value="">Select Beneficiary</option>
                <option *ngFor="let staff of allStaffs" [value]="staff._id">{{staff.name}}</option>
              </select>
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Amount</label>
            <input type="number" formControlName="amount" class="form-control" [ngClass]="{ 'is-invalid': submitted && addBenefit.get('amount')?.invalid}"/>
            <div *ngIf="submitted && addBenefit.get('amount')?.invalid" class="invalid-feedback">
                <div *ngIf="addBenefit.get('amount')?.hasError('required')">Amount is required</div>
            </div>
        </div>

       
        
    </div>
</form>  
<div class="buttons" style="margin-top: 20px;">
    <div>
        <div id="cancel-btn" mat-dialog-close type="button" class="btn btn-lg btn-md">Cancel</div>
    </div>

    <div>
        <button (click)="addBenefits()" style="background-color:#B5861E;padding:5px 75px; color:#fff" type="button" class="btn btn btn-lg">Create</button>
    </div>
</div>
 
</mat-dialog-content> 