<div mat-dialog-title style="display: flex; justify-content: space-between;border-bottom: 1px solid gray;">
    <div style="display:flex; justify-content:space-between; width:100%">
        <div style="font-size: 15px; color:#00356B; font-weight: 500; ">Add New Event</div>
        <div style="font-size: 12px; font-weight:lighter;"><input style="margin-top:10px" class="form-check-input" type="checkbox" value="" id="flexCheckDefault"> 
            <div style="color: #7A7A7A; font-size:12px">
                Event is Important
            </div>
        </div>
    </div>
</div>

<mat-dialog-content>
    <form [formGroup]="createEvents">
    <div class="row">
        <div class="col-md-3" style="padding-top:7px; font-size:15px">
            <label class="form-label"  style="color:#00356B;  font-weight: 500;">Event Name</label>
        </div>
        <div class="col-md-9">
            <div class="form-outline">
                <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && createEvents.get('name')?.invalid}" />
                <div *ngIf="submitted && createEvents.get('name')?.invalid" class="invalid-feedback">
                    <div *ngIf="createEvents.get('name')?.hasError('required')">Name is required</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin-top:10px;">
        <div class="col-md-3" style="padding-top:7px; font-size:15px">
            <label class="form-label"  style="color:#00356B;  font-weight: 500;">Event Description</label>
        </div>
        <div class="col-md-9">
            <div class="form-outline">
                <input type="text" formControlName="description" class="form-control"/>
            </div>
        </div>
    </div>

    <div class="row" style="margin-top:10px;">
        <div class="col-md-2" style="padding-top:7px; font-size:15px">
            <label class="form-label"  style="color:#00356B;  font-weight: 500;">Event Time</label>
        </div>
        <div class="col-md-3">
            <div class="form-outline">
                <label class="form-label"  style="color:#7A7A7A;font-size:11px">Event start date</label>
                <input type="date" formControlName="start_date" class="form-control" [ngClass]="{ 'is-invalid': submitted && createEvents.get('start_date')?.invalid}" />
                <div *ngIf="submitted && createEvents.get('start_date')?.invalid" class="invalid-feedback">
                    <div *ngIf="createEvents.get('start_date')?.hasError('required')">Start Date is required</div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-outline">
                <label class="form-label"  style="color:#7A7A7A; font-size:11px">Time</label>
                <input type="time" formControlName="start_time" class="form-control" />
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-outline">
                <label class="form-label"  style="color:#7A7A7A; font-size:11px">Event end date</label>
                <input type="date" formControlName="end_date" class="form-control" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-outline">
                <label class="form-label"  style="color:#7A7A7A; font-size:11px">Time</label>
                <input type="time" formControlName="end_time" class="form-control" />
            </div>
        </div>
    </div>

    <!-- <div class="row" style="margin-top:10px;">
        <div class="col-md-3" style="padding-top:7px; font-size:15px">
            <label class="form-label"  style="color:#00356B;  font-weight: 500;">Repeat</label>
        </div>
        <div class="col-md-9">
            <div class="form-outline">
                <input type="text" class="form-control"/>
            </div>
        </div>
    </div> -->

    <div class="row" style="margin-top:10px;">
        <div class="col-md-3" style="padding-top:7px; font-size:15px">
            <label class="form-label"  style="color:#00356B; font-weight: 500;">Location</label>
        </div>
        <div class="col-md-9">
            <div class="form-outline">
                <input formControlName="location" type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && createEvents.get('location')?.invalid}"/>
                <div *ngIf="submitted && createEvents.get('location')?.invalid" class="invalid-feedback">
                    <div *ngIf="createEvents.get('location')?.hasError('required')">Location is required</div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="row" style="margin-top:10px;">
        <div class="col-md-3" style="padding-top:7px; font-size:15px">
            <label class="form-label"  style="color:#00356B; font-weight: 500;">Attendees</label>
        </div>
        <div class="col-md-9">
            <div class="form-outline">
                <input type="text" formControlName="attendees" class="form-control" />
            </div>
        </div>
    </div>
    <div class="row" style="margin-top:10px;">
        <div class="col-md-3" style="padding-top:7px; font-size:15px">
        </div>
        <div class="col-md-9">
            <div class="form-outline" style="margin-left:20px">
                <div style="font-size: 12px; font-weight:lighter">
                    <input style="margin-top:5px" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <div style="color: #7A7A7A; padding-top:2px">Notify when attendees confirm or decline invitation</div> 
                </div>
            </div>
        </div>
    </div>
</form>
<div style="display:flex; margin-top:10px; justify-content: right !important;">
    <div><button style="background-color:#C4C4C4; color:#00356B; margin-right:20px" mat-dialog-close type="button" class="btn btn-primary btn-md">Cancel</button></div>
    <div><button (click)="newEvent()" style="background-color:#B5861E; color:#fff" type="button" class="btn btn btn-md">Create</button></div>
</div>
</mat-dialog-content>