import { SettingService } from './../../services/settings/setting.service';
import { AddFaculty, GetFaculty, DeleteFaculty, UpdateFaculty } from './../../actions/settings/faculty.action';
import { Faculty } from './../../models/settings.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'


export class FacultyStateModel {
    faculties!: Faculty[];   
}

@State<FacultyStateModel>({
    name: 'faculties',
    defaults: {
        faculties: [], 
 
    }
})


@Injectable()
export class facultyState {

    constructor(private settingService: SettingService) {
    }

    @Selector()
    static getFaculty(state: FacultyStateModel) {
        return state.faculties;
    }


    @Action(GetFaculty)
    getFaculty({getState, setState}: StateContext<FacultyStateModel>) {
        return this.settingService.getFaculty().pipe(tap((result) => {
            const state = getState();
            setState({
                ...state,
                faculties: result.data,
            });
        }));
    }



    @Action(AddFaculty)
    addFaculty({getState, patchState}: StateContext<FacultyStateModel>, {payload}: AddFaculty) {
        return this.settingService.createFaculty(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                faculties: [...state.faculties, result.data]
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err.statusText
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }


    @Action(DeleteFaculty)
    deleteTodo({getState, setState}: StateContext<FacultyStateModel>, {_id}: DeleteFaculty) {
        return this.settingService.deleteFaculty(_id).pipe(tap((result:any) => { 
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            } 
            const state = getState();
            const filteredArray = state.faculties.filter(item => item._id !== result.data._id)
            setState({
                ...state,
                faculties: filteredArray,
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }

    
    @Action(UpdateFaculty)
    updateFaculty({getState, setState}: StateContext<FacultyStateModel>, {payload, _id}: UpdateFaculty) {
        return this.settingService.updateFaculty(payload, _id).pipe(tap((result) => {
            console.log(result.data)
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            } 
            const state = getState();
            const facultyList = [...state.faculties];
            console.log(facultyList)
            const facultyIndex = facultyList.findIndex(item => item._id === result.data._id);
            facultyList[facultyIndex] = result;
            console.log(facultyList)
            setState({
                ...state,
                faculties: facultyList,
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }


    alert = {
        title: <any> "",
        message: <any> "",
        icon: <any> "",
    }



    alertWithMessage(title:any,message:any,icon:any){
        Swal.fire({
        title: title,
        text: message,
        icon: icon,
        })
    }
}
