
<mat-dialog-content>
    <div class="container">
        <div>
            <div style="display:flex; justify-content: space-between; padding:10px">
                <div style="display: flex;">
                    <div>
                        <div style="color:#00356B; font-size:16px; height:70%; padding-right:8px; font-weight:500">NEW MAIL</div>
                    </div>
                </div>
                
                <div style="display: flex;">
                    <div><i style="color:#B5861E; background-color: #EDEDEE; padding:8px; border-radius: 20px;margin-right: 5px;" class="far fa-trash-alt"></i></div>
                    <div><i style="color:#B5861E; background-color: #EDEDEE; padding:8px; border-radius: 20px;margin-right: 5px;" class="far fa-trash-alt"></i></div>
                    <div><i style="padding:10px;" mat-dialog-close class="fas fa-times"></i></div>
                </div>
            </div>
            <form [formGroup]="sendMail">
                <div class="b-reply" style="font-weight: 500;color:#7A7A7A; font-size:14px; font-weight:500">From: <input type="text"></div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="b-reply" style="font-weight: 500;color:#7A7A7A; font-size:14px; font-weight:500">To: <input type="text"></div>
                    </div>
                    <div class="col-md-6">
                        <div class="b-reply" style="font-weight: 500;color:#7A7A7A; font-size:14px; font-weight:500">CC: <input type="text"></div>
                    </div>
                </div>
                <div class="b-reply" style="font-weight: 500;color:#7A7A7A; font-size:14px; font-weight:600"> <input formControlName="title" placeholder="Subject" type="text"></div>


                <div class="mail-message">
                <textarea name="" id="" formControlName="content" cols="70" placeholder="Type message here" rows="5"></textarea>
                </div>
            </form>
    
            <div class="file-u" style="display: flex; justify-content: space-between; margin: 0px 20px">
                <div style="display: flex;">
                    <div><img src="assets/images/file uploader.svg"></div>
                    <div>Attach File</div>
                </div>
                <div class="mid-tab">
                    <button (click)="send()" type="button" class="btn btn">Send</button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>