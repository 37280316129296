<div class="container" style="margin:40px 0px">
    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Education Type</label>
                <input type="text" class="form-control" />
            </div>  
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Institution</label>
                <input type="text" class="form-control" />
                <button style="background-color:#00356B; color:#fff; margin-top:5px; float:right;" type="button" class="btn btn">Add More</button>
            </div>  
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Other Info</label>
                <input type="text" class="form-control" />
            </div>  
        </div>
    </div>
</div>