import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AddMemo, DeleteMemo, GetMemos, UpdateMemo, SetSelectedMemo } from './../actions/memo.action';
import {Memo} from '../../shared/models/memo.model';
import {MemoService} from '../services/memo/memo.service';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'

export class MemoStateModel {
    memos!: Memo[];
}

@State<MemoStateModel>({
    name: 'memos',
    defaults: {
        memos: []
    }
})

@Injectable()
export class MemoState {

    constructor(private memoService: MemoService) {
    }

    @Selector()
    static getMemos(state: MemoStateModel) {
        return state.memos;
    }


    @Action(AddMemo)
    addTodo({getState, patchState}: StateContext<MemoStateModel>, {payload}: AddMemo) {
        return this.memoService.createMemo(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                memos: [...state.memos, result.data]
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err.statusText
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }



    @Action(GetMemos)
    getMemos({getState, setState}: StateContext<MemoStateModel>) {
        return this.memoService.getMemos().pipe(tap((result) => {
            const state = getState();
            setState({
                ...state,
                memos: result.data,
            });
        }));
    }


    @Action(DeleteMemo)
    deleteTodo({getState, setState}: StateContext<MemoStateModel>, {_id}: DeleteMemo) {
        return this.memoService.deleteMemo(_id).pipe(tap((result:any) => { 
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            } 
            const state = getState();
            const filteredArray = state.memos.filter(item => item._id !== result.data._id)
            setState({
                ...state,
                memos: filteredArray,
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }



    alert = {
        title: <any> "",
        message: <any> "",
        icon: <any> "",
      }
    
    
    
      alertWithMessage(title:any,message:any,icon:any){
        Swal.fire({
          title: title,
          text: message,
          icon: icon,
        })
    }

}