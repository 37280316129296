<div style="margin:0px 5%">
    <div style="display:flex; padding:10px 5px">
        <div (click)="email()" [ngClass]="{'active': mail}" style="margin-right:45px; font-weight:600;cursor: pointer; color:#A5A5A5"><i style="color:#A5A5A5; padding-right:10px;" class="fas fa-envelope"></i>MAILS</div>
        <div style="font-weight:600;cursor: pointer; color:#A5A5A5" (click)="weChat()" [ngClass]="{'active': chat}"><i style="color:#A5A5A5; padding-right:10px;" class="fas fa-comments"></i>CHATS</div>   
    </div>

</div>


<div *ngIf="mail">
    <app-mail></app-mail>
</div>


<div *ngIf="chat">
    <app-chat></app-chat>
</div>