<div mat-dialog-title style="display: flex; justify-content: space-between;border-bottom: 1px solid gray;">
    <div style="font-size: 20px; color:#00356B; font-weight: 500;">Add Faculty</div>
</div>

<mat-dialog-content>

    <div style="width:100%">
        <form [formGroup]="facultyForm">
            <div class="md-form md-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Name</label>
                <input type="text" mdbValidation formControlName="name" mdbInput class="form-control"/>
                <mdb-error *ngIf="input.name  && input.name.invalid && (input.name.dirty || input.name.touched)">
                    <span *ngIf="input.name.errors!.required">Name is required</span>
                </mdb-error>
                <mdb-success *ngIf="input.name  && input.name.valid && (input.name.dirty || input.name.touched)">Name valid</mdb-success>
            </div>
    
            <div class="form-outline" style="margin-top:20px">
                <label class="form-label" style="color:#00356B">Select Dean</label>
                <select style="padding:10px" class="form-select form-select-sm" aria-label=".form-select-sm example" formControlName="dean" [(ngModel)]="selectedDean" (ngModelChange)="selectDean($event)">
                    <option value="614b3313ec24d4002302f3bb">Michael Babajide</option>
                    <option value="6149f73df55030002309a139">Garuba Sunday</option>
                    <option value="6149f5eff55030002309a135">Kenneth cole</option>
                  </select>
            </div>
           
        </form>
        <div id="action-btn">
            <div><button id="cancel-btn" mat-dialog-close type="button" class="btn btn-primary btn-md" mdbWavesEffect>Cancel</button></div>
            <div><button [disabled]="facultyForm.invalid"  (click)="addFaculty()" style="background-color:#00356B; color:#fff" type="button; padding:0px 100px" mdbWavesEffect class="btn btn btn-md">Add
            </button></div>
        </div>
    </div>

</mat-dialog-content>