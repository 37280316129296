import { GetDepartment } from './../../../shared/actions/settings/depatments.action';
import { MatDialogRef } from '@angular/material/dialog';
import { AddStaff } from './../../../shared/actions/hr-mgt/staff.action';
import { Store } from '@ngxs/store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addstaffmodal',
  templateUrl: './addstaffmodal.component.html',
  styleUrls: ['./addstaffmodal.component.scss']
})
export class AddstaffmodalComponent implements OnInit {

  addStaff!: FormGroup;
  selectedRole: any;
  selectedJobTitle: any;
  roleValue: any;
  data:any;
  submitted = false
  selectedDepartment: any
  jobTitleValue: any;
  departments$: any;
  departmentValue:any;
  allDepartments: any


  constructor(
    private fb: FormBuilder,
    private store: Store,
    public dialogRef: MatDialogRef<AddstaffmodalComponent>

  ) { }

  ngOnInit(): void {
    this.addStaff = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      position: [''],
      salary: [''],
      department: ['']
    })
    this.getAllDepartments()
  }


  addNewtaff(){
    this.submitted = true
    if(this.addStaff.invalid){
      return;
    }else{
      const { name, email, phone, password, position, salary, department } = this.addStaff.value
      this.data = {
        name,
        email,
        phone,
        salary,
        password,
        position,
        department: this.departmentValue
  
      }
      this.store.dispatch(new AddStaff(this.data))
      this.dialogRef.close()
    }
  }


  getAllDepartments(){
    this.store.dispatch(new (GetDepartment))
    this.departments$ = this.store.select(state => state.departments.departments).subscribe(data =>{
      this.allDepartments = data
      // console.log(this.allStaffs)
    });
  }


   // getting staff role value
   onSelected(val:any){
    this.customFunction(val)
  }

  customFunction(val:any){
    this.roleValue= val;
  }


     // getting staff job_type value
     SelectedType(val:any){
      this.jobFunction(val)
    }
  
    jobFunction(val:any){
      this.jobTitleValue = val;
    }


    // getting staff department value
    selectDepartment(val:any){
    this.departmentFunction(val)
  }
      
  departmentFunction(val:any){
    this.departmentValue = val;
  }
    

}
