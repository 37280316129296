import { GetBudgets, AddBudget } from './../actions/budget.action';
import { OperationService } from './../services/operation/operation.service';
import { Budget } from './../models/operations.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'




export class BudgetStateModel {
    budgets!: Budget[];
}

@State<BudgetStateModel>({
    name: 'budgets',
    defaults: {
        budgets: [],
    }
})

@Injectable()
export class budgetState {

    constructor(private operationService: OperationService) {
    }

    @Selector()
    static GetBudgets(state: BudgetStateModel) {
        return state.budgets;
    }


    @Action(GetBudgets)
    GetStock({getState, setState}: StateContext<BudgetStateModel>) {
        return this.operationService.getBudgets().pipe(tap((result) => {
            console.log(result)
            const state = getState();
            setState({
                ...state,
                budgets: result.data,
            });
        }));
    }
}