<div class="container-fluid">

  <div class="row">
    <div class="col-md-7">
      <img src="assets/images/background/mu-background.png" class="bg-image">
    </div>
    <div class="col-md-5">

      <div class="text-center">
        <img src="assets/images/logo/erewa-logo.png" height="70" width="auto" alt="logo">
        <h6 class="f-w-700">Tony G. Erewa-college-of-education Staff Manager.</h6>
      </div>

      <mdb-card class="text-center">
        <mdb-card-body>
          <mdb-card-title>
            <h2>Sign into your Account</h2>
            <h4 class="text-muted mb-2"></h4>
          </mdb-card-title>
          <mdb-card-text class="px-4">
            <f orm [formGroup]="validatingForm">
              <div class="form-group">
                <div class="md-form md-outline">
                  <!--{{LoginData.email}}-->
                  <input [(ngModel)]="LoginData.username" mdbValidation type="email" class="form-control py-2"
                    formControlName="email" mdbInput>
                  <label for="email">Email</label>

                  <mdb-error *ngIf="input.email  && input.email.invalid && (input.email.dirty || input.email.touched)">
                    <span *ngIf="input.email.errors!.required">Email is required</span>
                    <span *ngIf="input.email.errors!.email">This email address is not valid</span>
                  </mdb-error>
                  <mdb-success *ngIf="input.email  && input.email.valid && (input.email.dirty || input.email.touched)">
                    Email valid</mdb-success>
                </div>
                <div class="my-5 md-form md-outline form-lg">
                  <input mdbValidation [(ngModel)]="LoginData.password" name="password" type="password" id="password"
                    class="form-control py-2" mdbInput formControlName="password">
                  <label for="password">Password</label>
                  <mdb-error
                    *ngIf="input.password && input.password.invalid && (input.password.dirty || input.password.touched)">
                    <span *ngIf="input.password.errors!.required">Password is required</span>
                    <!-- <span *ngIf="input.password.errors!.pattern">must contain caps</span> -->
                    <span *ngIf="input.password.errors!.minlength"> minimum character (8)</span>

                  </mdb-error>
                  <mdb-success
                    *ngIf="input.password && input.password.valid && (input.password.dirty || input.password.touched)">
                    Password valid</mdb-success>

                </div>
              </div>

            </f>
          </mdb-card-text>
          <button (click)="LoginUser()" [disabled]="validatingForm.invalid" mdbBtn class="submit-btn"
            mdbWavesEffect>Sign In
            <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-dark" role="status"
              aria-hidden="true"></span>
            <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-secondary" role="status"
              aria-hidden="true"></span>
            <span *ngIf="_loader" class="spinner-grow spinner-grow-sm text-success" role="status"
              aria-hidden="true"></span>
          </button>
          <hr />
          <div class="row">
            <div class="col-md-10">
              <p class="text-inverse text-left m-b-0">Tony G. Erewa-college-of-education Staff Manager.</p>
              <p class="text-inverse text-left f-w-600"><a [routerLink]="['/']">Go back to main website?</a></p>
            </div>
            <div class="col-md-2">
              <img src="assets/images/logo/erewa-logo-small.png" alt="small-logo.png">
            </div>
          </div>
          <!-- <a href="#!" class="card-link">Clear</a> -->
        </mdb-card-body>
      </mdb-card>

    </div>
  </div>

</div>