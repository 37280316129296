
import {Memo} from '../models/memo.model';

export class AddMemo {
    static readonly type = '[Memo] Add';

    constructor(public payload: Memo) {
    }
}

export class GetMemos {
    static readonly type = '[Memo] Get';
}

export class UpdateMemo {
    static readonly type = '[Memo] Update';

    constructor(public payload: Memo, public id: number) {
    }
}

export class DeleteMemo {
    static readonly type = '[Memo] Delete';

    constructor(public _id: number) {
    }
}

export class SetSelectedMemo {
    static readonly type = '[Memo] Set';

    constructor(public payload: Memo) {
    }
}