import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit {

  title = "Work Report"

  ngOnInit(): void {
  }


  constructor() {

  }

  workComp:boolean=false;
  stockComp:boolean=true;
  eventComp:boolean=false;
  activityComp:boolean=false;
  tripComp:boolean=false;
  leaveComp: boolean=false;
  requestComp:boolean=false;
  budgetComp:boolean=false;
  

  work(){
      this.workComp=true,
      this.stockComp=false;
      this.eventComp=false;
      this.activityComp=false;
      this.tripComp=false;
      this.requestComp=false;
      this.budgetComp=false;
      this.leaveComp=false
  }

  stock(){
      this.stockComp=true;
      this.workComp=false,
      this.eventComp=false;
      this.activityComp=false;
      this.tripComp=false;
      this.requestComp=false;
      this.budgetComp=false;
      this.leaveComp=false
  }

  event(){
      this.eventComp=true;
      this.workComp=false,
      this.stockComp=false;
      this.activityComp=false;
      this.tripComp=false;
      this.requestComp=false;
      this.budgetComp=false;
      this.leaveComp=false

  }
  
  trip(){
    this.tripComp=true;
    this.workComp=false,
    this.stockComp=false;
    this.eventComp=false;
    this.activityComp=false;
    this.requestComp=false;
    this.budgetComp=false;
    this.leaveComp=false
  }

  leave(){
    this.tripComp=false;
    this.leaveComp=true
    this.workComp=false,
    this.stockComp=false;
    this.eventComp=false;
    this.activityComp=false;
    this.requestComp=false;
    this.budgetComp=false;
  }

  request(){
    this.requestComp=true;
    this.workComp=false,
    this.stockComp=false;
    this.eventComp=false;
    this.activityComp=false;
    this.tripComp=false;
    this.budgetComp=false;
    this.leaveComp=false
  }
  budget(){
    this.budgetComp=true;
    this.workComp=false,
    this.stockComp=false;
    this.eventComp=false;
    this.activityComp=false;
    this.tripComp=false;
    this.requestComp=false;
    this.leaveComp=false
  }

}
