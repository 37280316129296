<!-- Footer -->
<footer class="page-footer font-small blue darken-4 pt-4">



  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2006 - {{_date | date: 'yyyy'}}
    <a href="#"> Tony G. Erewa College of Education.</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->