
<mat-dialog-content>
    <div class="container">
        <div>
            <div style="display:flex; justify-content: space-between; padding:10px">
                <div style="display: flex;">
                    <div>
                        <div style="color:#00356B; font-size:16px; height:70%; padding-right:8px; font-weight:500">Set Time Limit</div>
                    </div>
                </div>
                
                <div style="display: flex;">
                    <div><i style="padding:10px;" mat-dialog-close class="fas fa-times"></i></div>
                </div>
            </div>
            <form [formGroup]="createTime">
                <div class="row">
                    <div class="col-md-6" id="">
                        <label class="form-label"  style="color:#00356B; font-weight:500">Early</label>
                        <input type="date" formControlName="early_time" class="form-control"/>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label"  style="color:#00356B; font-weight:500">Dismissal Time</label>
                        <input type="date" formControlName="dismisal_time" class="form-control"/>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12" style="padding-top:10px">
                        <label class="form-label"  style="color:#00356B; font-weight:500">Remarks</label>
                        <input type="text" formControlName="remark" class="form-control"/>
                    </div>
                </div> -->
                <div (click)="addTime()" style="float: right;margin-top:20px"><button style="font-size:13px; color:#fff; background-color:#B5861E" type="button" class="btn btn">Set Time</button></div>
            </form>
        </div>
    </div>
</mat-dialog-content>