import { Store } from '@ngxs/store';
import { GetReport } from './../../../../shared/actions/works.action';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  reports$: any;
  allReports: any;

  constructor(
    public store: Store
  ) { }

  title="Work Report"

  ngOnInit(): void {
    this.store.dispatch(new (GetReport))
    this.reports$ = this.store.select(state => state.reports.reports.data).subscribe(data =>{
      this.allReports = data
    });
  }

}
