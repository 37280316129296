import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  mail:boolean=true;
  chat:boolean=false;

  constructor() { }

  ngOnInit(): void {
  }

  email(){
    this.mail=true
    this.chat=false;
  }

  weChat(){
    this.mail=false
    this.chat=true;
  }

}
