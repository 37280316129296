import { AddAnnouncement, GetAnnouncement, DeleteAnnouncement } from './../../shared/actions/announcement.action';
import Swal from 'sweetalert2'
import { Store } from '@ngxs/store';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {


  announceForm!: FormGroup
  recipient! : number
  selectedCategory!: string
  public submitted = false; 
  data:any;
  allAnnouncement: any;
  announcements$: any;
  public  message: any =""

  constructor(
    public fb: FormBuilder,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.announceForm = this.fb.group({
      content: ['', Validators.compose([Validators.required])],
      date: ['', [Validators.required]],
      sender: ['', Validators.compose([Validators.required])],
    })

    this.store.dispatch(new (GetAnnouncement))
    this.announcements$ = this.store.select(state => state.announcements.announcements).subscribe(data =>{
      console.log(data)
      this.allAnnouncement = data
    });
  }

  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }



  alertWithMessage(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }
  
  announce(){
    this.submitted = true
    if(this.announceForm.invalid){
      return;
    }else{
      console.log(this.announceForm)
      const{ content, date, sender  } = this.announceForm.value
  
      this.data = {
        content,
        date,
        sender: this.recipient
      }
      console.log(this.data)
  
      this.store.dispatch(new AddAnnouncement(this.data)).subscribe((response:any) =>{
        console.log(response)
        if(response.announcements['announcements'][0].success == false){
          this.alert.title = "Failed!"
          this.alert.message = response.announcements['announcements'][0].message
          this.alert.icon = "error"
          this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }
  
        if(response.announcements['announcements'][0].success == true){
          this.alert.title = "Successful!"
          this.alert.message = response.announcements['announcements'][0].message
          this.alert.icon = "success"
          this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }},
      err => {
        this.alert.title = "Failed!"
        this.alert.message = err.error.msg
        this.alert.icon = "error"
  
        this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
      })
    }
  }


  // delete announcement
  deleteAnnouncement(announcement: any){
    this.store.dispatch(new DeleteAnnouncement(announcement._id));
  }


   // getting sender ID
   onSelected(val:any){
    this.customFunction(val)
  }

  customFunction(val:any){
    this.recipient = val;
  }

}
