<div class="container">
    <section class="attendance-summary">
        <div class="card">
            <div class="att-container">
                <div class="total">
                    <div class="att-header">Total Attendance</div>
                    <div class="att-no">36</div>
                </div>
                <div class="late">
                    <div class="att-header">Late</div>
                    <div class="att-no">4</div>
                </div>
                <div class="early">
                    <div class="att-header">Early</div>
                    <div class="att-no">40</div>
                </div>
            </div>
        </div>
    </section>
    <section id="top-row">
        <div class="row">
            <div class="budget-holder" >
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>


    <section id="mid-inputs-controls">  
        <div class="row">
            <div class="col-md-3">
                <input id="input-field" placeholder="Search"  type="date">
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
                <input id="input-field" type="date" >   
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2" id="c-btn">
                <button id="createBtn" (click)="setTime()" style="font-size:13px; color:#fff; background-color:#B5861E" class="btn btn" >Set time limit</button>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <div style="color:#00356B; font-size:16px;padding:10px; font-weight: 500;">Today: Monday</div>
            <table class="table">
                <thead id="t-head">
                  <tr>
                    <td scope="col">ID</td>
                    <td scope="col">Employer Name</td>
                    <td scope="col">Department</td>
                    <td scope="col">Check In</td>
                    <td scope="col">Check Out</td>
                    <td scope="col">Duration</td>
                    <td scope="col">Edit</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">1</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>
                        <div class="edit-btn" style="margin-right:10px;">  
                            <button type="button" style="color:#00356B;font-size: 10px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">2</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>
                        <div class="edit-btn" style="margin-right:10px;">  
                            <button type="button" style="color:#00356B;font-size: 10px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">3</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>
                        <div class="edit-btn" style="margin-right:10px;">  
                            <button type="button" style="color:#00356B;font-size: 10px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>

</div>    
