<div class="container" style="margin:40px 0px">

    <div class="row">
        <div class="col-md-6">
            <div class="form-outline" style="margin-top:20px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Position</label>
                <input type="text" class="form-control" />
            </div>

            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Phone Number</label>
                <input type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-outline" style="margin-top:20px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Department</label>
                <input type="text" class="form-control" />
            </div>
            

            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Gender</label>
                <input type="text" class="form-control" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Home Address</label>
                <input type="text" class="form-control" />
            </div>  
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Fathers Name</label>
                <input type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B;font-weight:500">Mother's Name</label>
                <input type="text" class="form-control" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Next of Kin</label>
                <input type="text" class="form-control" />
            </div>  
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Address of Next of Kin</label>
                <input type="text" class="form-control" />
            </div>  
        </div>
    </div>

</div>