import { GetEvent, AddEvent } from './../actions/events.action';
import { OperationService } from './../services/operation/operation.service';
import { Events } from './../models/operations.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'



export class EventStateModel {
    events!: Event[];
}

@State<EventStateModel>({
    name: 'events',
    defaults: {
        events: [],
    }
})


@Injectable()
export class EventState {

    constructor(private operationService: OperationService) {
    }

    @Selector()
    static GetEvents(state: EventStateModel) {
        return state.events;
    }


    @Action(GetEvent)
    GetEvent({getState, setState}: StateContext<EventStateModel>) {
        return this.operationService.getEvents().pipe(tap((result) => {
            console.log(result)
            const state = getState();
            setState({
                ...state,
                events: result.data,
            });
        }));
    }


    @Action(AddEvent)
    addStock({getState, patchState}: StateContext<EventStateModel>, {payload}: AddEvent) {
        return this.operationService.createEvents(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                events: [...state.events, result.data]
            });
        },err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }



    
    alert = {
        title: <any> "",
        message: <any> "",
        icon: <any> "",
    }



  alertWithMessage(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }

}

