import { MessageService } from './../../../shared/services/messages/message.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-message-modal',
  templateUrl: './new-message-modal.component.html',
  styleUrls: ['./new-message-modal.component.scss']
})
export class NewMessageModalComponent implements OnInit {

  sendMail!: FormGroup
  userID: any
  data: any

  constructor(
    public fb: FormBuilder,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {

    this.sendMail = this.fb.group({
      title: [''],
      content: [''],
      sender: ['']
    })
    this.getId()
  }


  send(){
    const{ title, content, sender } = this.sendMail.value

    this.data = {
      title,
      content,
      sender: this.userID
    }
    this.messageService.createMessage(this.data).subscribe(resp =>{
      console.log(resp)
    })
    console.log(this.sendMail.value)
  }


  // get sender id
  getId(){
    this.userID = localStorage.getItem('auth._id')
    this.userID = this.userID.substring(1,25)
    console.log(this.userID)
  }

}
