import { GetFaculty } from './../../actions/settings/faculty.action';
import { Faculty, Department } from './../../models/settings.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable, isDevMode } from '@angular/core';


let Url = ""
if (isDevMode()) {
  Url = environment.endpoint;
} else {
  console.log('💪 Production!');
}

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  APIUrl =  Url

  constructor(
    private http: HttpClient, 
  ) { }

createFaculty(faculty: Faculty){
  return this.http.post<any>(`${this.APIUrl}/api/admin/faculty/create`, faculty)
}

  
getFaculty(){
  return this.http.get<any>(`${this.APIUrl}/api/admin/faculty`)
}

deleteFaculty(id: any) {
  return this.http.delete(`${this.APIUrl}/api/admin/faculty/` + id + `/delete`);
}

updateFaculty(faculty: Faculty, id: any) {
  return this.http.put<any>(`${this.APIUrl}/api/admin/faculty/` + id + `/update`, faculty);
}

createDepartment(department: Department){
  return this.http.post<any>(`${this.APIUrl}/api/admin/department/create`, department)
}

getDepartment(){
  return this.http.get<any>(`${this.APIUrl}/api/admin/department`)
}

deleteDepartment(id: any) {
  return this.http.delete(`${this.APIUrl}/api/admin/department/` + id + `/delete`);
}

updateDepartment(department: Department, id: any) {
  return this.http.put<any>(`${this.APIUrl}/api/admin/department/` + id + `/update`, department);
}


}
