<div class="container" style="margin-top:30px">
    <div style="display:flex; justify-content: space-between;">
        <div style="background-color:#F4F4FB; padding:10px 30px; text-align: center !important;">
            <img style="width:50px;" src="assets/images/document2.png"/>
            <p style="font-size: 10px; color:#00356B;font-weight:600">CV</p>
        </div>
        <div style="background-color:#F4F4FB;padding:10px 30px; text-align: center !important;">
            <img style="width:50px;" src="assets/images/document2.png"/>
            <p style="font-size: 10px; color:#00356B; font-weight:600">BIRTH CERTIFICATE</p>
        </div>
        <div style="background-color:#F4F4FB;padding:10px 30px; text-align: center !important;">
            <img style="width:50px;" src="assets/images/document2.png"/>
        <p style="font-size: 10px; color:#00356B; font-weight:600">RESULT</p></div>
        <div style="background-color:#F4F4FB; padding:10px 30px;text-align: center !important;">
            <img style="width:50px;" src="assets/images/document2.png"/>
            <p style="font-size: 10px; color:#00356B; font-weight:600">CERTIFICATE</p>
        </div>
    </div>
</div>