<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder">
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls">  
        <div class="row">
            <div class="col-md-3">
                <input id="input-field" class="search" placeholder="Search"  type="text">
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
               <input id="input-field"  class="input" type="date">            
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
                <input id="input-field" class="input" type="date">   
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2" id="a-select">
                <select id="mySelect" class="form-select" aria-label="Default select example" id="mySelect2">
                    <option value="2">Import</option>
                    <option value="3">Export</option>
                </select>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table">
            <thead style="font-weight: lighter !important;">
                <tr style="color:#00356B; font-weight:500">
                <td scope="col">Request title</td>
                <td scope="col">Sender</td>
                <td scope="col">Recipient</td>
                <td scope="col">Department</td>
                <td scope="col">Date</td>
                <td scope="col">Action</td>
                <td scope="col"></td>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="allRequests.length">

                    <tr *ngFor="let request of allRequests">
                        <td>{{ request.request_type }}</td>
                        <td>user</td>
                        <td>{{ request.recipient_department }}</td>
                        <td>user department</td>
                        <td>{{ request.start_date | date }}</td>
                        <td><button style="background-color: #fff; border: 1px solid rgb(194, 187, 187);"><i style="color:#B5861E" class="fas fa-eye"></i></button></td>
                        <td><button style="font-size:13px; color:#fff; background-color:#B5861E" type="button" class="btn btn">Approve</button>
                    </tr>
                </ng-container>
            </tbody>
            </table>
        </div>
        <div>
            <ngx-skeleton-loader [theme]="{'height.px': 50}" count="10" appearance="line"  *ngIf="!allRequests.length"></ngx-skeleton-loader>
        </div>
      </div>

</div>    
