import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { SideNavDirection } from './sidebar-direction';


@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.scss']
})
export class DashboardSidebarComponent implements OnInit {

  LinkItems: any[] = [
    // {
    //   url: "home",
    //   name: "Dashboard",
    //   icon: "tachometer-alt"

    // },
    {
      url: "home",
      name: "Dashboard",
      icon: "tachometer-alt"

    },
    {
      url: "message",
      name: "Messages",
      icon: "american-sign-language-interpreting"
 
    },
    {
     url: "operations",
     name: "Operations",
     icon: "digital-tachograph"

   },
   {
     url: "hr-mgt",
     name: "Hr Management",
     icon: "digital-tachograph"

   },
   {
     url: "finance",
     name: "Finance",
     icon: "home"

   },
   {
     url: "file-mgt",
     name: "File Management",
     icon: "file-alt"

   },
   {
    url: "settings",
    name: "Settings",
    icon: "setting"

  },
  {
    url: "clearance-available",
    name: "Logout",
    icon: "logout"

  },


  ];

 showSideNav: Observable<boolean> | undefined;

 @Input() sidenavTemplateRef: any;
 @Input() duration: number = 0.25;
 @Input() navWidth: number = window.innerWidth;
 @Input() direction: SideNavDirection = SideNavDirection.Left;
 
 constructor(private navService: NavigationService){}

 ngOnInit(): void {
   this.showSideNav = this.navService.getShowNav();
 }

 onSidebarClose() {
   this.navService.setShowNav(false);
 }

 getSideNavBarStyle(showNav: boolean) {
   let navBarStyle: any = {};
   
   navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
   navBarStyle.width = this.navWidth + 'px';
   navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';
   
   return navBarStyle;
 }

}
