<div class="container" style="padding-bottom:30px">
    <section id="top-row">
        <div class="row">
            <div class="budget-holder" >
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>


    <div class="card">
        <div style="display: flex; justify-content: space-between;">
            <div style="padding:20px 40px; font-weight:600; color:#00356B">Name & Address</div>
            <div (click)="edit()" style="text-align:right !important; padding:20px 40px; font-weight:600; color:#00356B; cursor: pointer;">Edit</div>
        </div>
        <div class="profile-details">
            <p>Maduka University</p>
            <p>Info@madukauniversity.com</p>
            <p>Enugu State</p>
            <p>No. 35 Ajose Adeogun Street Utako</p>
        </div>
    </div>


    <div style="margin-top:30px; color:#00356B">Organogram</div>
    <div class="card">
        <div class="row">
            <div style="padding:20px 40px; font-weight:600; color:#00356B" class="col-md-6">Be right back</div>
            <div style="text-align:right !important; padding:20px 40px; font-weight:600; color:#00356B" class="col-md-6">Edit</div>
        </div>
    </div>

</div>