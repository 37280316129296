import { GetStocks } from './../../../../shared/actions/stock.action';
import { Store } from '@ngxs/store';
import { AddStockModalComponent } from './../add-stock-modal/add-stock-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {

  stocks$:any
  allStocks: any


  title="Stock & Inventory"

  constructor(
    public dialog: MatDialog,
    public store: Store
  ) { }

  ngOnInit(): void {
    this.getStocks()
  }

  getStocks(){
    this.store.dispatch(new (GetStocks))
    this.stocks$ = this.store.select(state => state.stocks.stocks).subscribe(data =>{
      this.allStocks = data 
    });
  }

  addStock(){
    this.dialog.open(AddStockModalComponent,{height:'300px',width:'500px'});
  }

}
