import { GetEvent } from './../../../../shared/actions/events.action';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { EventModalComponent } from '../event-modal/event-modal.component';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  title = "Events"
  events$! : any
  allEvents: any


  constructor(
    public dialog: MatDialog,
    private store: Store,

  ) { }

  ngOnInit(): void {
    this.store.dispatch(new (GetEvent))
    this.events$ = this.store.select(state => state.events.events).subscribe(data =>{
      this.allEvents = data
    });

  }

  addEvents(){
    this.dialog.open(EventModalComponent,{height:'500px',width:'750px'});
  }

}
