import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Department, RequestsData, DataImportExport } from './../dashboardrequests/dashboardrequests.component';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dashboardbudgets',
  templateUrl: './dashboardbudgets.component.html',
  styleUrls: ['./dashboardbudgets.component.scss']
})
export class DashboardbudgetsComponent implements OnInit {

  //icons
  user: string = "user";

  
  dataSource: MatTableDataSource<RequestsData>;

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) set matpaginator(paginator: MatPaginator) {
   this.dataSource.paginator = paginator;
}
 @ViewChild(MatSort) set matSort(sort: MatSort) {
   this.dataSource.sort = sort;
}
data: RequestsData[] = 
[
 {
   title: 'Request For Leave',
   sender: 'Chukwudera Ezgggge',
   recipient: 'HOD/Ayodeji',
   department: 'ICT',
   date: new Date()
 },
 {
   title: 'Request For Leave',
   sender: 'Chukwudera Eze',
   recipient: 'HOD/Ayodeji',
   department: 'ICT',
   date: new Date()
 },
 {
   title: 'Request For Leave',
   sender: 'Chukwudera Eze',
   recipient: 'HOD/Ayodeji',
   department: 'ICT',
   date: new Date()
 },
 {
   title: 'Request For Leave',
   sender: 'Chukwudera Eze',
   recipient: 'HOD/Ayodeji',
   department: 'ICT',
   date: new Date()
 },
 {
   title: 'Request For Leave',
   sender: 'Chukwudera Eze',
   recipient: 'HOD/Ayodeji',
   department: 'ICT',
   date: new Date()
 },

 {
  title: 'Request For Leave',
  sender: 'Chukwudera Eze',
  recipient: 'HOD/Ayodeji',
  department: 'ICT',
  date: new Date()
},

 
];

department: Department[] = [
 {
   value: 'CSC', text: 'Computer Science',
 },
 {
   value: 'MEE', text: 'Mechanical Engineering', 
 },
 {
   value: 'EEE', text: 'Electrical Engineering',
   
 }
]

importexport: DataImportExport[] = [
 {
   value: 'export', text: 'EXPORT', 
 },
 {
   value: 'import', text: 'IMPORT',
 }
]


 constructor() { 
   this.dataSource = new MatTableDataSource(this.data)
 }

 ngOnInit(): void {
 }


 displayedColumns: string[] = ['title', 'sender', 'recipient', 'department', 'date', 'status'];

 applyFilter(event: Event) {
   const filterValue = (event.target as HTMLInputElement).value;
   this.dataSource.filter = filterValue.trim().toLowerCase();

   if (this.dataSource.paginator) {
     this.dataSource.paginator.firstPage();
   }
 }


}
