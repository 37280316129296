import { Stock } from './../models/operations.model';


export class AddStock {
    static readonly type = '[Stock] Add';

    constructor(public payload: Stock) {
    }
}

export class GetStocks {
    static readonly type = '[Stock] Get';
}