import { NewMessageModalComponent } from './../new-message-modal/new-message-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {

  constructor(
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
  }

  createMsg(){
    console.log('hi')
    this.dialog.open(NewMessageModalComponent, {height:'450px', width:'750px'});
  }



}
