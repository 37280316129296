import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  structure:boolean=true;
  faculty:boolean=false;
  department:boolean=false;
  position:boolean=false;
  role:boolean=false;


  coyStructure(){
    this.structure=true
    this.faculty=false;
    this.department=false;
    this.position=false;
    this.role=false;
  }

  coyFaculty(){
    this.structure=false
    this.faculty=true;
    this.department=false;
    this.position=false;
    this.role=false;
  }

  coyDepartment(){
    this.structure=false
    this.faculty=false;
    this.department=true;
    this.position=false;
    this.role=false;
  }

  coyPosition(){
    this.structure=false
    this.faculty=false;
    this.department=false;
    this.position=true;
    this.role=false;
  }

  coyRole(){
    this.structure=false
    this.faculty=false;
    this.department=false;
    this.position=false;
    this.role=true;
  }




}
