import { AddEvent } from './../../../../shared/actions/events.action';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss']
})
export class EventModalComponent implements OnInit {

  createEvents!: FormGroup
  submitted = false

  constructor(
    public fb: FormBuilder,
    public store: Store,
  ) { }

  ngOnInit(): void {
    this.createEvents = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      description: [''],
      location: ['', Validators.compose([Validators.required])],
      attendees: [''],
      start_date: ['', Validators.compose([Validators.required])],
      end_date: [''],
      start_time: [''],
      end_time: ['']
    })

  }



  newEvent(){
    this.submitted = true
    if(this.createEvents.invalid){
      return;
    }else{
      console.log(this.createEvents.value)
      this.store.dispatch(new AddEvent(this.createEvents.value))
    }
  }

}
