import { AddMemo, GetMemos, DeleteMemo } from './../../shared/actions/memo.action';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Select, Store} from '@ngxs/store';
import Swal from 'sweetalert2'



@Component({
  selector: 'app-create-memo',
  templateUrl: './create-memo.component.html',
  styleUrls: ['./create-memo.component.scss']
})
export class CreateMemoComponent implements OnInit {

  memoForm!: FormGroup;
  recipient! : number;
  selectedCategory: any;
  public submitted = false; 
  data:any
  memos$: any;
  allMemos: any;
  public  message: any =""


  alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }


  constructor(
    public fb: FormBuilder,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.memoForm = this.fb.group({
      title: ['', Validators.compose([Validators.required])],
      content: ['', Validators.compose([Validators.required])],
      date: [''],
      sender: ['', [Validators.required]]
    })

    this.store.dispatch(new (GetMemos))
    this.memos$ = this.store.select(state => state.memos.memos).subscribe(data =>{
      this.allMemos = data
    });

  }

  get formControl() {
    return this.memoForm.controls;
  }

  alertWithMessage(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }




  onSubmit(){
    this.submitted = true; 
    if(this.memoForm.invalid){
      return;
    }else{
      const{ title, content, date, sender  } = this.memoForm.value
  
      this.data = {
        title,
        content,
        date,
        sender: this.recipient
      }
      this.store.dispatch(new AddMemo(this.data))
      this.memoForm.reset()
    }
  }


  deleteMemo(memo:any){
    this.store.dispatch(new DeleteMemo(memo._id));
  }



   // getting sender ID
   onSelected(val:any){
    this.customFunction(val)
  }

  customFunction(val:any){
    this.recipient = val;
  }


}
