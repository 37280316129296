import { Department } from './../../models/settings.model';


export class AddDepartment {
    static readonly type = '[Department] Add';

    constructor(public payload: Department) {
    }
}

export class GetDepartment {
    static readonly type = '[Department] Get';
}


export class DeleteDepartment {
    static readonly type = '[Department] Delete';

    constructor(public _id: number) {
    }
}

export class UpdateDepartment {
    static readonly type = '[Department] Update';

    constructor(public payload: Department, public _id: number) {
    }
}
