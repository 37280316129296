<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder" >
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls">  
        <div class="row" id="controls">
            <div class="col-md-3">
                <input id="input-field" placeholder="Search" type="text">
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
               <input  id="input-field" type="date">            
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
                <input  id="input-field" type="date">   
            </div>
            <div class="col-md-2" >
                <select id="select-filed" class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2" id="c-btn">
                <button id="createBtn" (click)="addStock()" style="font-size:13px; color:#fff; background-color:#B5861E" type="button" class="btn">Add New</button>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table">
                <thead style="font-weight: lighter !important;">
                <tr style="color:#00356B; font-weight:500">
                    <td scope="col">ID</td>
                    <td scope="col">Stocked By</td>
                    <td scope="col">Product</td>
                    <td scope="col">Quantity</td>
                    <td scope="col">Remarks</td>
                    <td scope="col">Action</td>
                </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="allStocks.length">
                        <tr *ngFor="let stock of allStocks let i = index">
                            <td scope="row">{{ i + 1 }}</td>
                            <td>{{ stock._id }}</td>
                            <td>{{ stock.name }}</td>
                            <td>{{ stock.quantity }}</td>
                            <td>{{ stock.remark }}</td>
                            <td></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div>
            <ngx-skeleton-loader [theme]="{'height.px': 50}" count="10" appearance="line"  *ngIf="!allStocks.length"></ngx-skeleton-loader>
        </div>
      </div>
</div>    
