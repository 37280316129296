
<mat-dialog-content>
    <div class="container">
        <div>
            <div style="display:flex; justify-content: space-between; padding:10px">
                <div style="display: flex;">
                    <div>
                        <div style="color:#00356B; font-size:16px; height:70%; padding-right:8px; font-weight:500">Stocks/Inventory</div>
                    </div>
                </div>
                
                <div style="display: flex;">
                    <div><i style="padding:10px;" mat-dialog-close class="fas fa-times"></i></div>
                </div>
            </div>
            <form [formGroup]="createStock">
                <div class="row">
                    <div class="col-md-8" id="">
                        <label class="form-label"  style="color:#00356B; font-weight:500">Product Name</label>
                        <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && createStock.get('name')?.invalid}"/>
                        <div *ngIf="submitted && createStock.get('name')?.invalid" class="invalid-feedback">
                            <div *ngIf="createStock.get('name')?.hasError('required')">Product name required</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label"  style="color:#00356B; font-weight:500">Qty</label>
                        <input type="number" formControlName="quantity" class="form-control" [ngClass]="{ 'is-invalid': submitted && createStock.get('quantity')?.invalid}"/>
                        <div *ngIf="submitted && createStock.get('quantity')?.invalid" class="invalid-feedback">
                            <div *ngIf="createStock.get('quantity')?.hasError('required')">Product quantity required</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="padding-top:10px">
                        <label class="form-label"  style="color:#00356B; font-weight:500">Remarks</label>
                        <input type="text" formControlName="remark" class="form-control"/>
                    </div>
                </div>
                <div (click)="inventory()" style="float: right;margin-top:20px"><button style="font-size:13px; color:#fff; background-color:#B5861E" type="button" class="btn btn">Create</button></div>
            </form>
        </div>
    </div>
</mat-dialog-content>