import { AddStaff, GetStaff } from './../../actions/hr-mgt/staff.action';
import { HrService } from './../../services/hr-mgt/hr.service';
import { Staff } from './../../models/hr-mgt.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'



export class StaffStateModel {
    staffs!: Staff[];
}

@State<StaffStateModel>({
    name: 'staffs',
    defaults: {
        staffs: []
    }
})

@Injectable()

export class StaffState {

    constructor(private hrService: HrService) {
    }

    @Selector()
    static GetStaff(state: StaffStateModel) {
        return state.staffs;
    }


    @Action(AddStaff)
    addTodo({getState, patchState}: StateContext<StaffStateModel>, {payload}: AddStaff) {
        return this.hrService.createStaff(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                staffs: [...state.staffs, result.data]
            });
        },err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }



    @Action(GetStaff)
    getStaff({getState, setState}: StateContext<StaffStateModel>) {
        return this.hrService.getStaff().pipe(tap((result) => {
            const state = getState();
            setState({
                ...state,
                staffs: result.data,
            });
        }));
    }



    alert = {
        title: <any> "",
        message: <any> "",
        icon: <any> "",
      }
    
    
    
      alertWithMessage(title:any,message:any,icon:any){
        Swal.fire({
          title: title,
          text: message,
          icon: icon,
        })
    }

}