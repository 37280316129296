import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-management',
  templateUrl: './hr-management.component.html',
  styleUrls: ['./hr-management.component.scss']
})
export class HrManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  staff:boolean=true;
  bonus:boolean=false;
  attendance:boolean=false;
  recruit:boolean=false;


  staffBtn(){
    this.staff=true
    this.bonus=false;
    this.attendance=false;
    this.recruit=false;
  }

  bonusBtn(){
    this.staff=false
    this.bonus=true;
    this.attendance=false;
    this.recruit=false;
  }

  attendanceBtn(){
    this.staff=false
    this.bonus=false;
    this.attendance=true;
    this.recruit=false;
  }

  recruitBtn(){

    this.staff=false
    this.bonus=false;
    this.attendance=false;
    this.recruit=true;
    
  }

}
