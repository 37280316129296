<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder" >
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls">  
        <div class="row">
            <div class="col-md-3">
                <input id="input-field" placeholder="Search"  type="text">
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
               <input id="input-field" type="date">            
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
                <input id="input-field" type="date">   
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2" id="a-select">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option value="2">Import</option>
                    <option value="3">Export</option>
                </select>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table">
                <thead style="font-weight: lighter !important;">
                <tr style="color:#00356B; font-weight:500">
                    <td scope="col">Budget title</td>
                    <td scope="col">Description</td>
                    <td scope="col">Prepared by</td>
                    <td scope="col">Department</td>
                    <td scope="col">Date</td>
                    <td scope="col">Status</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let budget of allBudgets">
                    <td>{{ budget.title }}</td>
                    <td>{{ budget.description }}</td>
                    <td>user</td>
                    <td>{{budget.department}}</td>
                    <td>{{ budget.date | date }}</td>
                    <td><button style="font-size:13px; color:#fff; background-color:#B5861E" type="button" class="btn btn">Approve</button>
                </tr>
                </tbody>
            </table>
        </div>
      </div>

</div>    
