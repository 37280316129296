<div mat-dialog-title style="display: flex; justify-content: space-between;border-bottom: 1px solid gray;">
    <div style="font-size: 20px; color:#00356B; font-weight: 500;">Edit Faculty</div>
</div>

<mat-dialog-content>
    <form [formGroup]="facultyForm">
        <div style="width:100%">
            <div class="md-form md-outline" style="margin-top:10px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Faculty Name</label>
                <input type="text" mdbValidation formControlName="name" mdbInput class="form-control"/>
                <mdb-error *ngIf="input.name  && input.name.invalid && (input.name.dirty || input.name.touched)">
                    <span *ngIf="input.name.errors!.required">Name is required</span>
                </mdb-error>
                <mdb-success *ngIf="input.name  && input.name.valid && (input.name.dirty || input.name.touched)">Name valid</mdb-success>
            </div>
    
            <div class="form-outline" style="margin-top:20px">
                <label class="form-label" style="color:#00356B;font-weight:500">Dean</label>
                <select style="padding:10px" class="form-select form-select-sm" aria-label=".form-select-sm example" formControlName="deanID" [(ngModel)]="selectedDepartment" (ngModelChange)="selectDepartment($event)">
                    <option value="809dc047373030002309d51a">ICT</option>
                    <option value="898dc047373030002665d51a">Maintenance</option>
                    <option value="898dc047373077802309d51a">Finance</option>
                  </select>
            </div>
    
            <!-- <div class="form-outline" style="margin-top:20px">
                <label class="form-label"  style="color:#00356B; font-weight:500">Select HOD</label>
                <select style="padding:10px" class="form-select form-select-sm" aria-label=".form-select-sm example" formControlName="head_of_dept" [(ngModel)]="selectedHeadOfDepartment" (ngModelChange)="selectHeadOfDepartment($event)">
                    <option value="614dcfe530443700233d9fdf">Michael Babajide</option>
                    <option value="614dcfe530443700233d9fdf">Garuba Sunday</option>
                    <option value="614dcfe530443700233d9fdf">Kenneth cole</option>
                  </select>
            </div> -->
    
    
        </div>
        
    </form>
     <div id="action-btn">
         <div><button id="cancel-btn" mat-dialog-close type="button" class="btn btn-primary btn-md">Cancel</button></div>
         <div><button (click)="editFaculty()" [disabled]="facultyForm.invalid" style="background-color:#00356B;  color:#fff" type="button; padding:0px 100px" class="btn btn btn-md">Update</button></div>
     </div>

</mat-dialog-content>