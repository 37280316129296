import { MatDialogRef } from '@angular/material/dialog';
import { AddBonus } from './../../../shared/actions/hr-mgt/bonus.action';
import { GetDepartment } from './../../../shared/actions/settings/depatments.action';
import { Store } from '@ngxs/store';
import { GetStaff } from './../../../shared/actions/hr-mgt/staff.action';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newbenfits',
  templateUrl: './newbenfits.component.html',
  styleUrls: ['./newbenfits.component.scss']
})
export class NewbenfitsComponent implements OnInit {

  selectedDepartment: any
  departmentValue:any;
  addBenefit!: FormGroup;
  submitted = false;
  staffs$ : any;
  allStaffs : any;
  departments$: any;
  allDepartments: any

  constructor(
    private fb: FormBuilder,
    private store: Store,
    public dialogRef: MatDialogRef<NewbenfitsComponent>
  ) { }

  ngOnInit(): void {
    this.addBenefit = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      department: ['', Validators.compose([Validators.required])],
      staff: ['', [Validators.required]],
      amount: ['', [Validators.required]],
  
    })
    this.getStocks()
    this.getAllDepartments()
  }

  getStocks(){
    this.store.dispatch(new (GetStaff))
    this.staffs$ = this.store.select(state => state.staffs.staffs).subscribe(data =>{
      this.allStaffs = data
      console.log(this.allStaffs)
    });
  }


  getAllDepartments(){
    this.store.dispatch(new (GetDepartment))
    this.departments$ = this.store.select(state => state.departments.departments).subscribe(data =>{
      this.allDepartments = data
      console.log(this.allStaffs)
    });
  }



  addBenefits(){
    this.submitted = true;
    if(this.addBenefit.invalid){
      return;
    }else{
      const { name, department, amount, staff } = this.addBenefit.value
  
      const data = {
        name,
        department: this.departmentValue,
        staff,
        amount
      }
      this.store.dispatch(new AddBonus(data))
      this.dialogRef.close()    
    }
  }



    // getting staff department value
    selectDepartment(val:any){
      this.departmentFunction(val)
    }
        
    departmentFunction(val:any){
      this.departmentValue = val;
    }

}
