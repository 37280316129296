import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mailmessage',
  templateUrl: './mailmessage.component.html',
  styleUrls: ['./mailmessage.component.scss']
})
export class MailmessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
