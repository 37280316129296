<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder">
                <span>Hr Management/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls" style="margin-bottom:10px">  
        <div class="row">
            <div class="col-md-3">
                <input id="mySelect" class="search-box" placeholder="Search"  type="text">
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>All</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Select Deapartment</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Export</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2">
                <button style="font-size:13px; color:#fff; background-color:#B5861E" class="btn btn" (click)="addStaffs()">Create New</button>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table" style="color:#00356B;">
                <thead>
                  <tr style="color:#00356B; font-weight:500">
                    <td scope="col"><mat-checkbox style="padding-right:8px"></mat-checkbox>Name</td>
                    <td scope="col">Department</td>
                    <td scope="col">Email</td>
                    <td scope="col">Phone</td>
                    <td scope="col">Date Updated</td>
                    <td scope="col">Role</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="allStaffs.length">
                  <tr (click)="profile()" *ngFor="let staff of allStaffs">
                    <td scope="row"><mat-checkbox style="padding-right:8px"></mat-checkbox>{{ staff.name }}</td>
                    <td>{{ staff.department }}</td>
                    <td>{{ staff.user.username }}</td>
                    <td>{{staff.phone}}</td>
                    <td>{{ staff.created_at | date }}</td>
                    <td>@mdo</td>
                    <td>Otto</td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
        </div>
        <div>
            <ngx-skeleton-loader [theme]="{'height.px': 50}" count="10" appearance="line"  *ngIf="!allStaffs.length"></ngx-skeleton-loader>
        </div>
      </div>

</div>    
