import { AddBonus, GetBonus, DeleteBonus, UpdateBonus } from './../../actions/hr-mgt/bonus.action';
import { HrService } from './../../services/hr-mgt/hr.service';
import { Bonus } from './../../models/hr-mgt.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'



export class BonusStateModel {
    bonuses!: Bonus[];
}

@State<BonusStateModel>({
    name: 'bonuses',
    defaults: {
        bonuses: []
    }
})

@Injectable()

export class BonusState {

    constructor(private hrService: HrService) {
    }

    @Selector()
    static GetBonus(state: BonusStateModel) {
        return state.bonuses;
    }


    @Action(AddBonus)
    addBonus({getState, patchState}: StateContext<BonusStateModel>, {payload}: AddBonus) {
        return this.hrService.createBonus(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                bonuses: [...state.bonuses, result.data]
            });
        },err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }

    @Action(GetBonus)
    getStaff({getState, setState}: StateContext<BonusStateModel>) {
        return this.hrService.getBonus().pipe(tap((result) => {
            const state = getState();
            setState({
                ...state,
                bonuses: result.data,
            });
        }));
    }


    @Action(DeleteBonus)
    deleteBonus({getState, setState}: StateContext<BonusStateModel>, {_id}: DeleteBonus) {
        return this.hrService.deleteBonus(_id).pipe(tap((result:any) => { 
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            } 
            const state = getState();
            const filteredArray = state.bonuses.filter((item:any) => item._id !== result.data._id)
            setState({
                ...state,
                bonuses: filteredArray,
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }


    
    @Action(UpdateBonus)
    updateDepartment({getState, setState}: StateContext<BonusStateModel>, {payload, _id}: UpdateBonus) {
        return this.hrService.updateBonus(payload, _id).pipe(tap((result) => {
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            } 
            const state = getState();
            const bonusList = [...state.bonuses];
            const departmentIndex = bonusList.findIndex((item:any) => item._id === result.data._id);
            bonusList[departmentIndex] = result.data;
            setState({
                ...state,
                bonuses: bonusList,
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }


    alert = {
        title: <any> "",
        message: <any> "",
        icon: <any> "",
      }
    
    
    
      alertWithMessage(title:any,message:any,icon:any){
        Swal.fire({
          title: title,
          text: message,
          icon: icon,
        })
    }

}