<div class="container" style="padding-bottom: 30px;">
    <section id="mid-inputs-controls" style="margin-bottom:10px; justify-content: space-between !important;">  
        <div class="row">
            <div class="col-md-6">
                <div style="font-weight: 700; color:#00356B; font-size: 20px;">Faculty</div>
            </div>
         
           
            <div class="col-md-6" style="text-align: right !important;">
                <button (click)="addFaculty()" style="font-size:13px; color:#fff; background-color:#00356B" class="btn btn">Add Faculty</button>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table" style="color:#00356B;">
                <thead style="background-color: #00356B; color:#fff">
                  <tr style="font-weight:500">
                    <td scope="col">Faculty</td>
                    <td scope="col">Dean</td>
                    <td scope="col">Number of Department</td>
                    <td scope="col">Date Created</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="allFaculties.length">
                    <tr *ngFor="let faculty of allFaculties">
                      <td scope="row">{{faculty.name}}</td>
                      <td>Chukwudera Kenneth</td>
                      <td>12</td>
                      <td>{{faculty.created_at | date}}</td>
                      <td>
                        <div class="edit-btn" style="margin-right:10px; display: flex;">  
                          <button (click)="editFaculty(faculty)" type="button" style="color:#00356B;font-size: 12px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                          <div (click)="deleteFaculty(faculty)"><i style="font-size: 20px;padding-top:3px; cursor: pointer;" class="far fa-trash-alt"></i></div>
                      </div>
                      </td>
  
                    </tr>

                  </ng-container>

                </tbody>
              </table>
        </div>
        <div>
          <ngx-skeleton-loader [theme]="{'height.px': 50}" count="10" appearance="line"  *ngIf="!allFaculties.length"></ngx-skeleton-loader>
        </div>
      </div>
</div>