import { Store } from '@ngxs/store';
import { GetRequest } from './../../../../shared/actions/request.actions';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  title="Request"
  requests$: any
  allRequests: any

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new (GetRequest))
    this.requests$ = this.store.select(state => state.requests.requests).subscribe(data =>{
      console.log(data)
      this.allRequests = data
      console.log(data)
    });
  }

}
