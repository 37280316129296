<div class="container">
    <section id="top-row">
        <div class="row">
            <div class="col-md-2 budget-holder" >
                <span>Requests</span>
            </div>
            <button class="col-md-2 offset-8 ml-lg-auto budget-holder-request-button" >
                <span>Make Requests</span>
            </button>
        </div>
    </section>

    <section id="mid-inputs-controls">
        <div class="row">
            <div class="col-md">
                <mat-form-field appearance="fill" >
                    <!-- <mat-label>Favorite food</mat-label> -->
                    <input type="search" matInput placeholder="Search" value="">
                  </mat-form-field>
            </div>
            <div class="col-md">
                <mat-form-field appearance="fill">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>                  
            </div>
            <div class="col-md">
                <mat-form-field appearance="fill">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  
            </div>
            <div class="col-md">
                <mat-form-field appearance="fill">
                <mat-label>Select Department</mat-label>
                <mat-select>
                    <mat-option *ngFor="let dept of department" [value]="dept.value">
                    {{dept.text}}
                    </mat-option>
                </mat-select>
                </mat-form-field>
        </div>
        <div class="col-md">
            <mat-form-field appearance="fill">
                <mat-label>Select Data Type</mat-label>
                <mat-select>
                    <mat-option *ngFor="let transfer of importexport" [value]="transfer.value">
                    {{transfer.text}}
                    </mat-option>
                </mat-select>
                </mat-form-field>
        </div>
        </div>
    </section>

    <section id="table-data">
        <!-- <div  class="mat-elevation-z8"> -->
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
          
              <!-- Request Title Column -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Request Title </th>
                <td mat-cell *matCellDef="let row"> {{row.title}} </td>
              </ng-container>

              <!-- Sender Column -->
              <ng-container matColumnDef="sender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sender </th>
                <td mat-cell *matCellDef="let row"> {{row.sender}} </td>
              </ng-container>

               <!-- Recipient Column -->
               <ng-container matColumnDef="recipient">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient </th>
                <td mat-cell *matCellDef="let row"> {{row.recipient}} </td>
              </ng-container>

               <!-- Department Column -->
               <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                <td mat-cell *matCellDef="let row"> {{row.department}} </td>
              </ng-container>

                 <!-- Date Column -->
                 <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.date | date}} </td>
                  </ng-container>
          
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
              <!-- Row shown when there is no matching data. -->
              <!-- <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr> -->
            </table>
          
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          <!-- </div> -->
          
    </section>

</div>

