<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder" >
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls">  
        <div class="row">
            <div class="col-md-3">
                <input style="padding: 6px 2px ; padding-left:20px; border:none" placeholder="Search"  type="text">
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
               <input style="padding: 6px 2px ; border:none" type="date">            
            </div>
            <div class="col-md-2"  style="margin-right: 10px;">
                <input style="padding: 6px 2px; border:none" type="date">   
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example">
                    <option value="2">Import</option>
                    <option value="3">Export</option>
                </select>
            </div>
        </div>
    </section>

    <div class="card">
        <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handle</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
            </tbody>
          </table>
      </div>

</div>    
