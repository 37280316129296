<div class="container">
    <h2 class="super-admin-text">SuperAdmin</h2>
    <!--Top tabs-->
    <section id="top-tabs">
        <div class="row">
            <div class="col-md top-tabs">
              <h3>Staff</h3>
              <div class="top-tabs-number">
                  7000
              </div>
              <div class="top-tabs-icon">
                  <mdb-icon fas icon="{{user}}" ></mdb-icon>
              </div>
            </div>
            <div class="col-md top-tabs">
              <h3>Faculty</h3>
              <div class="top-tabs-number">
                  20
              </div>
              <div class="top-tabs-icon">
                  <mdb-icon fas icon="{{city}}" ></mdb-icon>
              </div>
            </div>
            <div class="col-md top-tabs">
              <h3>Department</h3>
              <div class="top-tabs-number">1000</div>
              <div class="top-tabs-icon">
                  <mdb-icon fas icon="{{building}}"></mdb-icon>
              </div>
            </div>
            <div class="col-md top-tabs">
                <h3>Requests/Orders</h3>
              <div class="top-tabs-number">900</div>
              <div class="top-tabs-icon">
                  <mdb-icon fas icon="{{building}}" ></mdb-icon>
              </div>
            </div>
          </div>
    </section>


    <!--Mid Tabs-->
    <section id="mid-tabs">
        <div class="row">
            <div class="col-md mid-tabs"  (click)="goToRequestPage()" >
                <mdb-icon fas icon="{{user}}"></mdb-icon>
                <span>Request</span>
            </div>
            <div class="col-md mid-tabs" (click)="goToBudgetsPage()">
                <mdb-icon fas icon="{{user}}"></mdb-icon>
                <span>Budgets</span>
            </div>
            <div class="col-md mid-tabs">
                <mdb-icon fas icon="{{stickyNote}}"></mdb-icon>
                <span>Reports</span>
            </div>
            <div class="col-md mid-tabs">
                <mdb-icon fas icon="{{user}}"></mdb-icon>
                <span>Notice Board</span>
            </div>
            <div class="col-md mid-tabs">
                <mdb-icon fas icon="{{user}}"></mdb-icon>
                <span [routerLink]='["/Dashboard/memo"]'>Memo</span>
            </div>
          </div>
    </section>

    <div class="row">
        <div class="col-md-9">
            <div class="my-memo" style="display:flex; justify-content: space-between; margin-top:20px">
                <div class="feeds-text">Feeds/Memo</div>
                <div id="memo-action-btn" style="display: flex; margin-right:10px">
                    <div id="announce-btn" (click)="goToAnnouncement()" class="memo-btn" style="margin-right: 10px;">
                        <span><i class="fas fa-cross"></i>Make Announcement</span>
                    </div>
                    <div class="memo-btn" (click)="goToMemoPage()"> 
                        <span> <i class="fas fa-cross"></i> Memo</span>
                    </div>
                </div>
            </div>
          <section id="feeds" *ngIf="allMemos">
            <div *ngFor="let memo of allMemos" class="feed-tabs">
                <div style="display: flex;">
                    <div class="image">
                        <img src='assets/images/mission.jpeg' class="profile-picture" alt="profile picture"/>
                     </div>
                     <div class="feed-data">
                        <span class="details-tray" style="display: flex;">
                             <p class="feed-title">{{ memo.sender }}</p>  
                            <div class="sender">
                                 <mdb-icon style="font-size: 15px; margin-left: 20px; margin-top: 3px; color: #7a7a7a;" fas icon="{{caretRight}}"></mdb-icon>
                                <p style="margin-left: 10px;">By: HOD Finance Department</p>
                            </div>
                        </span>
                         <p class="feed-dateCreated">Today: {{ memo.created_at | date }}</p>
                         <p class="feed-messageTitle">{{memo.content}}</p>
                         <p class="feed-addressedTo">To: All Departments</p>
                         <p class="feed-message">{{ memo.content }}</p>
                     </div>
                </div>

            </div>
        </section>
        <div>
            <ngx-skeleton-loader [theme]="{'height.px': 50}" count="10" appearance="line"  *ngIf="!allMemos.length"></ngx-skeleton-loader>
        </div>
        </div>


        <div class="col-md-3">
          <section id="employee-mini-tabs">
            <div class="employees-online-tab">
                <div style="display: flex;">
                    <div>
                        <p>Employees Online</p>
                    </div> 
                    <div class="ml-auto" (click)="viewAllEmployees()">
                        View All
                    </div> 
                </div>
                <div style="display: flex;">
                    <div>
                        <p class="number-of-employees">{{onlineEmployees.length}}</p>
                    </div> 
                    <div class="ml-auto" *ngFor="let employeedata of onlineEmployees">
                        <img src={{employeedata.image}} class="employees-profile-picture" alt="profile-picture"/>
                    </div> 
                </div>
            </div>

            <div class="ml-auto calender">
                <span style="display: flex;">
                    <mdb-icon  far icon="{{calendar}}"></mdb-icon>
                    <h2>Calendar</h2>
                </span>
            </div>

            

          
            <div class="my-tasks">
                <div class="task-title">
                    <div class="task-header">
                        <div>My Tasks</div>
                        <div class="ml-auto">
                            <mdb-icon fas icon="{{plusCircle}}" ></mdb-icon>
                        </div>
                    </div>
                </div>
                <div class="tasks-info" *ngFor="let tasks of employeeTasks">
                    <div style="display: flex;">
                        <div>Ongoing</div>
                        <div class="col-md-5 ml-auto mini-task-number" >{{tasks.ongoingTasks}}
                            <span class="task-of-employees">0</span>
                        </div>
                    </div>
                    <hr/>
                    <div style="display: flex;">
                        <div>Completed</div>
                        <div class="col-md-5 ml-auto mini-task-number">{{tasks.completedTasks}}
                            <span class="task-of-employees">0</span>
                        </div>
                        
                    </div>
                    <hr/>
                    <div style="display: flex;">
                        <div>Assisting</div>
                        <div class="col-md-5 ml-auto mini-task-number">{{tasks.assistingTasks}}
                            <span class="task-of-employees">0</span>
                        </div>
                    </div>
                    <hr/>
                    <div style="display: flex;">
                        <div >Set by Me</div>
                        <div class="col-md-5 ml-auto mini-task-number">{{tasks.setByMeTasks}}
                            <span class="task-of-employees">0</span>
                        </div>
                    </div>
                    <hr/>
                    <div style="display: flex;">
                        <div >Following</div>
                        <div class="col-md-5 ml-auto mini-task-number">{{tasks.followingTasks}}
                            <span class="task-of-employees">0</span>
                        </div>
                    </div>
                </div>
            </div>

              
            <div class="employees-attendants">
                <h2>Employee Attendants</h2>
            </div>

          </section>
        </div>
      </div>


    
  </div>
