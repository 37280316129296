<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder">
                <span>Finance/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls;" style="margin-bottom:10px" >  
        <div class="row">
            <div class="col-md-3" style="margin-right: px;">
                <input style="padding:8px 2px;  padding-left:20px; border:none" placeholder="Search"  type="text">         
            </div>
            <div class="col-md-3" style="margin-right: px;">
                <input style="padding: 6px 2px; border:none" type="date"  >            
             </div>
            <!-- <div class="col-md-2">
                <select class="form-select" aria-label="Default select example">
                    <option selected>Year</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div> -->
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Department</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2" style="text-align: center !important;">
                <button style="font-size:13px; padding:7px 30px; font-weight: 500; color:#00356B; background-color:#fff" class="btn btn" >Export</button>
            </div>
            <div class="col-md-2">
                <button style="font-size:13px; color:#fff; background-color:#B5861E" class="btn btn" >Create New</button>
            </div>
        </div>
    </section>


    <div class="card">
        <div class="table-responsive">
            <table class="table" style="color:#00356B">
                <thead  style="background-color: #00356B;">
                  <tr style="color:#fff; font-weight:500;">
                    <td scope="col">Assets Name</td>
                    <td scope="col">Department</td>
                    <td scope="col">Assets Description</td>
                    <td scope="col">Date Updated</td>
                    <td scope="col">State</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>In Use</td>
                    <td>
                      <div class="edit-btn" style="margin-right:10px; display: flex;">  
                        <button type="button" style="color:#00356B;font-size: 12px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                        <div><i style="font-size: 20px;padding-top:3px; cursor: pointer;" class="far fa-trash-alt"></i></div>
                    </div>
                    </td>

                  </tr>
                  <tr>
                    <td>Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>In Use</td>
                    <td>
                      <div class="edit-btn" style="margin-right:10px; display: flex;">  
                        <button type="button" style="color:#00356B;font-size: 12px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                        <div><i style="font-size: 20px;padding-top:3px;cursor: pointer;" class="far fa-trash-alt"></i></div>
                    </div>
                    </td>
                   
                  </tr>
                  <tr>
                    <td>Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>In good shape</td>
                    <td>20/2/2018</td>
                    <td>In Use</td> 
                    <td>
                        <div class="edit-btn"  style="margin-right:10px; display: flex;">  
                            <button type="button" style="color:#00356B;font-size: 12px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                            <div><i style="font-size: 20px;padding-top:3px; cursor: pointer;" class="far fa-trash-alt"></i></div>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>

</div>    
