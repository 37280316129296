import { OperationService } from './../services/operation/operation.service';
import { Report } from './../models/operations.model';
import { AddReport, GetReport } from './../actions/works.action';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';


export class ReportStateModel {
    reports!: Report[];
}

@State<ReportStateModel>({
    name: 'reports',
    defaults: {
        reports: []
    }
})

@Injectable()
export class ReportState {

    constructor(private operationService: OperationService) {
    }

    @Selector()
    static GetReport(state: ReportStateModel) {
        return state.reports;
    }


    @Action(GetReport)
    GetReport({getState, setState}: StateContext<ReportStateModel>) {
        return this.operationService.getReport().pipe(tap((result) => {
            const state = getState();
            setState({
                ...state,
                reports: result,
            });
        }));
    }
}
