<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder">
                <span>Hr Management/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls" style="margin-bottom:10px; justify-content: space-between !important;">  
        <div class="row">
            <div class="col-md-6">
                <input id="input-field" placeholder="Search"  type="text">
            </div>
         
           
            <div class="col-md-6" style="text-align: right !important;">
                <button style="font-size:13px; color:#fff; background-color:#B5861E" class="btn btn" (click)="addBenefit()">Add New Benefit</button>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table" style="color:#00356B;">
                <thead>
                  <tr style="color:#00356B; font-weight:500">
                    <td scope="col">ID</td>
                    <td scope="col">Name</td>
                    <td scope="col">Department</td>
                    <td scope="col">Amount</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bonus of allBonuses let i = index">
                    <td scope="row">{{i + 1}}</td>
                    <td>{{bonus.name}}</td>
                    <td>{{bonus?.department.name}}</td>
                    <td>{{bonus.amount}}</td>
                    <td>
                      <div class="edit-btn" style="margin-right:10px; display: flex;">  
                        <button type="button" style="color:#00356B;font-size: 12px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                        <div (click)="deleteBenefit(bonus)"><i style="font-size: 20px;padding-top:3px; cursor: pointer;" class="far fa-trash-alt"></i></div>
                    </div>
                    </td>

                  </tr>
                </tbody>
              </table>
        </div>
      </div>

</div>    
