import { Bonus } from '../../models/hr-mgt.model';


export class AddBonus {
    static readonly type = '[Bonus] Add';

    constructor(public payload: Bonus) {
    }
}

export class GetBonus {
    static readonly type = '[Bonus] Get';
}


export class UpdateBonus {
    static readonly type = '[Bonus] Update';

    constructor(public payload: Bonus, public _id: number) {
    }
}

export class DeleteBonus {
    static readonly type = '[Bonus] Delete';

    constructor(public _id: number) {
    }
}