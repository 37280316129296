import { Store } from '@ngxs/store';
import { MatDialogRef } from '@angular/material/dialog';
import { AddDepartment } from './../../../shared/actions/settings/depatments.action';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-departmentmodal',
  templateUrl: './departmentmodal.component.html',
  styleUrls: ['./departmentmodal.component.scss']
})
export class DepartmentmodalComponent implements OnInit {

  selectedDepartment: any;
  selectedHeadOfDepartment: any;
  departmentValue:any;
  HodValue: any;
  departmentForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DepartmentmodalComponent>,
    private store: Store
  ) { }

  ngOnInit() {
    this.departmentForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      faculty: new FormControl(null, []),
      head_of_dept: new FormControl(null, []),
    });
  }

  get input(){
    return this.departmentForm.controls;
  }

  addDepartment(){
    this.store.dispatch(new AddDepartment(this.departmentForm.value))
    this.dialogRef.close()
  }



    // getting staff department value
    selectDepartment(val:any){
    this.departmentFunction(val)
  }
        
    departmentFunction(val:any){
      this.departmentValue = val;
      console.log(this.departmentValue)

  }

    // getting head of department value
    selectHeadOfDepartment(val:any){
      this.HodFunction(val)
    }
          
    HodFunction(val:any){
      this.HodValue = val;
      console.log(this.HodValue)

    }
    

}
