import { MatDialogRef } from '@angular/material/dialog';
import { AddFaculty, GetFaculty } from './../../../shared/actions/settings/faculty.action';
import { Store } from '@ngxs/store';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facultymodal',
  templateUrl: './facultymodal.component.html',
  styleUrls: ['./facultymodal.component.scss']
})
export class FacultymodalComponent implements OnInit {

  _loader = false;
  deanValue! : any
  selectedDean: any;
  facultyForm!: FormGroup
  faculties$! : any
  allFaculties! : any


  constructor(
    public store: Store,
    public dialogRef: MatDialogRef<FacultymodalComponent>

  ) { }

  ngOnInit() {
    this.facultyForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      dean: new FormControl(null, [Validators.required])
    });
    // this.getData()
  }

  get input(){
    return this.facultyForm.controls;
  }


  // getData(){
  //   this.store.dispatch(new (GetFaculty))
  //   this.faculties$ = this.store.select(state => state.faculties.faculties).subscribe(data =>{
  //     this.allFaculties = data;
  //     console.log(this.allFaculties)
  //   });
  // }

  addFaculty(){
    // console.log(this.facultyForm.value)
    this.store.dispatch(new AddFaculty(this.facultyForm.value))
    this.dialogRef.close()
  }

  
    // getting staff department value
    selectDean(val:any){
      this.deanFunction(val)
    }
        
    deanFunction(val:any){
      this.deanValue = val;
    }
     

}
