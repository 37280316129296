import { FacultyupdatemodalComponent } from './../facultymodal/facultyupdatemodal/facultyupdatemodal.component';
import { GetFaculty, DeleteFaculty } from './../../../shared/actions/settings/faculty.action';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FacultymodalComponent } from '../facultymodal/facultymodal.component';

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.scss']
})
export class FacultyComponent implements OnInit {

  faculties$: any
  allFaculties : any

  constructor(
    private dialog: MatDialog,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new (GetFaculty))
    this.faculties$ = this.store.select(state => state.faculties.faculties).subscribe(data =>{
      this.allFaculties = data;
    });
  }

  addFaculty(){
    this.dialog.open(FacultymodalComponent, {height:'330px',width:'500px'});
  }

  deleteFaculty(faculty:any){
    this.store.dispatch(new DeleteFaculty(faculty._id));
  }

  editFaculty(faculty:any){
    this.dialog.open(FacultyupdatemodalComponent, {height:'330px',width:'500px'});
    localStorage.setItem("facultyID", faculty._id)
    console.log(faculty._id)
  }


}
