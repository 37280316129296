export interface AuthStateModel {
  token: string | null;
  username: string | null;
  message: string | null;
  _id: string | null;
}
  
  export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: { username: string; password: string }) {}
  }
  
  export class Logout {
    static readonly type = '[Auth] Logout';
  }