import { AddTime } from 'src/app/shared/actions/hr-mgt/time.action';
import { HrService } from './../../services/hr-mgt/hr.service';
import { Time } from './../../models/hr-mgt.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'

export class TimeStateModel {
    times!: Time[];
}

@State<TimeStateModel>({
    name: 'times',
    defaults: {
        times: []
    }
})

@Injectable()

export class TimeState {

    constructor(private hrService: HrService) {
    }

    @Selector()
    static GetTime(state: TimeStateModel) {
        return state.times;
    }


    @Action(AddTime)
    addTodo({getState, patchState}: StateContext<TimeStateModel>, {payload}: AddTime) {
        return this.hrService.setTime(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                times: [...state.times, result.data]
            });
        },err =>{
            this.alert.title = "Failed!"
            this.alert.message = err
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }

    
    alert = {
        title: <any> "",
        message: <any> "",
        icon: <any> "",
      }
    
    
    
      alertWithMessage(title:any,message:any,icon:any){
        Swal.fire({
          title: title,
          text: message,
          icon: icon,
        })
    }

}
