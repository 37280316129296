import { GetBudgets } from './../../../../shared/actions/budget.action';
import { Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit {

  allBudgets: any
  budgets$ : any

  constructor(
    private store: Store
  ) { }

  title="Budget"

  ngOnInit(): void {
    this.store.dispatch(new (GetBudgets))
    this.budgets$ = this.store.select(state => state.budgets.budgets).subscribe(data =>{
      this.allBudgets = data
    });
  }

}
