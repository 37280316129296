import { Message } from './../models/message';
import { OperationService } from './../services/operation/operation.service';
import { GetStocks, AddStock } from './../actions/stock.action';
import { Stock } from './../models/operations.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'




export class StockStateModel {
    stocks!: Stock[];
}

@State<StockStateModel>({
    name: 'stocks',
    defaults: {
        stocks: [],
    }
})

@Injectable()
export class StockState {

    constructor(private operationService: OperationService) {
    }

    @Selector()
    static GetStocks(state: StockStateModel) {
        return state.stocks;
    }


    @Action(GetStocks)
    GetStock({getState, setState}: StateContext<StockStateModel>) {
        return this.operationService.getStock().pipe(tap((result) => {
            console.log(result)
            const state = getState();
            setState({
                ...state,
                stocks: result.data,
            });
        }));
    }


    @Action(AddStock)
    addStock({getState, patchState}: StateContext<StockStateModel>, {payload}: AddStock) {
        return this.operationService.createStock(payload).pipe(tap((result: any) => {
            const state = getState();
            if(result.success == true){
                this.alert.title = "Successful!"
                this.alert.message = result.message
                this.alert.icon = "success"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)   
            }else{
                this.alert.title = "Failed!"
                this.alert.message = result.message
                this.alert.icon = "error"
                this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
            }
            patchState({
                stocks: [...state.stocks, result.data]
            });
        }, err =>{
            this.alert.title = "Failed!"
            this.alert.message = err.statusText
            this.alert.icon = "error"
            this.alertWithMessage(this.alert.title, this.alert.message, this.alert.icon)
        }));
    }



    
alert = {
    title: <any> "",
    message: <any> "",
    icon: <any> "",
  }



  alertWithMessage(title:any,message:any,icon:any){
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
    })
  }


}