<div mat-dialog-title style="display: flex; justify-content: space-between;border-bottom: 1px solid gray;">
    <div style="font-size: 20px; color:#00356B; font-weight: 500; ">Add New User</div>
</div>

<mat-dialog-content>
    <form [formGroup]="addStaff">
    <div style="width:100%">
        <!-- <label class="form-label"  style="color:#00356B">Employee Type</label> -->
        <!-- <div class="form-outline">
            <select class="form-select form-select-sm" aria-label=".form-select-sm example" formControlName="job_title" [(ngModel)]="selectedJobTitle" (ngModelChange)="SelectedType($event)">
                <option value="Contractor">Contractor</option>
                <option value="Contract Staff">Contract Staff</option>
                <option value="Permanent Staff">Permanent Staff</option>
            </select>
        </div> -->

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Name</label>
            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && addStaff.get('name')?.invalid}" />
            <div *ngIf="submitted && addStaff.get('name')?.invalid" class="invalid-feedback">
                <div *ngIf="addStaff.get('name')?.hasError('required')">Name is required</div>
            </div>
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Email</label>
            <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && addStaff.get('email')?.invalid}" />
            <div *ngIf="submitted && addStaff.get('email')?.invalid" class="invalid-feedback">
                <div *ngIf="addStaff.get('email')?.hasError('required')">Email is required</div>
                <div *ngIf="addStaff.get('email')?.hasError('email')">Email must be a valid email address</div>
            </div>
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Phone Number</label>
            <input type="number" formControlName="phone" class="form-control" />
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label" style="color:#00356B">Select Department</label>
            <select style="padding:10px" class="form-select form-select-sm" aria-label=".form-select-sm example" formControlName="department" [(ngModel)]="selectedDepartment" (ngModelChange)="selectDepartment($event)">
                <option *ngFor="let department of allDepartments" [value]="department._id">{{department.name}}</option>
            </select>
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Select Rank/Position</label>
            <input type="text" formControlName="position"  class="form-control"/>
        </div>

        <!-- <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Assign Action/Role</label>
            <select style="padding:10px" class="form-select form-select-sm" aria-label=".form-select-sm example" formControlName="role" [(ngModel)]="selectedRole" (ngModelChange)="onSelected($event)">
                <option value="Admin">Admin</option>
                <option value="Staff">Staff</option>
                <option value="Jnr.Staff">Jnr.Staff</option>
              </select>
        </div> -->


        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Salary</label>
            <input type="text" formControlName="salary" class="form-control"/>
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B">Password</label>
            <input type="password" formControlName="password" class="form-control"  [ngClass]="{ 'is-invalid': submitted && addStaff.get('password')?.invalid}"/>
            <div *ngIf="submitted && addStaff.get('password')?.invalid" class="invalid-feedback">
                <div *ngIf="addStaff.get('password')?.hasError('required')">Password is required</div>
                <div  *ngIf="addStaff.get('password')?.hasError('minlength')">Password must be at least 6 characters</div>
            </div>
        </div> 
    </div>
</form>  
<div class="buttons" style="margin-top: 20px;">
    <div>
        <div id="cancel-btn" mat-dialog-close type="button" class="btn btn-lg btn-md">Cancel</div>
    </div>

    <div>
        <button (click)=" addNewtaff()" style="background-color:#B5861E;padding:5px 75px; color:#fff" type="button" class="btn btn btn-lg">Create</button>
    </div>
</div>
 
</mat-dialog-content> 