<div class="container" style="padding-bottom: 30px; padding-top:10px">
    <div (click)="home()" style="padding:5px 10px; border-radius:50px; width:30px; background-color: #D6D5EA;"><i class="fas fa-arrow-left"></i></div>
    <div class="row">
        <div class="col-md-6" style="padding:20px">
            <div style="display: flex;">
                <div><img src="assets/images/profile.svg"/></div>
                <div style="padding-top:20px">
                    <p style="color:#00356B; font-size:18px;font-weight:600">Chukwudera Keneth</p>
                    <p style="color: #7A7A7A;font-weight:500; font-size: 15px;">HOD/Manager</p>
                    <p style="color:#00356B;font-weight:500; font-size:13px;">chukwudera@gmail.com</p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div>
                <div style="margin-top:15px">
                   <button type="button" style="padding:5px 10px; font-weight:500; color:#00356B; font-size:10px; margin-right:10px" class="btn-outline" id="edit-btn">Dismiss Employee</button>
                   <button type="button" style="padding:5px 10px; font-weight:500;  color:#00356B;font-size:10px; margin-right:10px" id="edit-btn" class="btn-outline" data-mdb-ripple-color="dark">Delete Employee</button>
                   <button type="button" style="padding:5px 10px; font-weight:500;  font-size:10px; color:#00356B;" id="edit-btn" class="btn-outline" data-mdb-ripple-color="dark">Delete Employee</button>
                </div>
                <div style="margin-top:10px">
                    <button type="button" id="edit-btn" style="padding:5px 10px;font-weight:500;  font-size:10px; color:#00356B; margin-right:10px"  class="btn-outline" data-mdb-ripple-color="dark">Stop Salary</button>
                    <button type="button" id="edit-btn" style="padding:5px 10px;font-weight:500;  font-size:10px; color:#00356B; margin-right:10px"  class="btn-outline" data-mdb-ripple-color="dark">Delete Login</button>
                </div>
            </div>
        </div>
    </div>

    <div class="card" style="margin-bottom:30px">
        <div class="row">
            <div class="col-md-3">
            <div class="controller" style="padding:20px">
                <p  (click)="information()" [ngClass]="{'active': infoComp}">Basic information</p>
                <p (click)="education()" [ngClass]="{'active': educationComp}">Education</p>
                <p (click)="achievement()" [ngClass]="{'active': achievementComp}">Achievements</p>
                <p (click)="promotion()" [ngClass]="{'active': promotionComp}">Promotion</p>
                <p (click)="document()" [ngClass]="{'active': documentComp}">Documents</p>
                <p (click)="salary()" [ngClass]="{'active': salaryComp}">Salary & Retirement</p>
                <p (click)="bankdetails()" [ngClass]="{'active': bankdetailsComp}">Bank Details</p>
                <p (click)="retirement()" [ngClass]="{'active': retirementComp}">Retirement Date</p>
            </div>
            </div>
            <div class="col-md-9">
                <div *ngIf="infoComp">
                    <app-information></app-information>
                </div>

                <div *ngIf="educationComp">
                    <app-education></app-education>
                </div>
                <div *ngIf="achievementComp">
                    <app-achievements></app-achievements>
                </div>
                <div *ngIf="promotionComp">
                    <app-promotion></app-promotion>
                </div>
                <div *ngIf="documentComp">
                    <app-document></app-document>
                </div>
                <div *ngIf="salaryComp">
                    <app-salary></app-salary>
                </div>
                <div *ngIf="bankdetailsComp">
                    <app-bankdetails></app-bankdetails>
                </div>
                <div *ngIf="retirementComp">
                    <app-retirement></app-retirement>
                </div>
            </div>
        </div>
    </div>
</div>