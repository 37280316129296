<div class="container">
    
    <div class="memo-container">
        <form [formGroup]="announceForm">
            <div class="memo-content"><h2>Make Announcement</h2></div>
            <p>
                If you would like to share a memo within your organization, You can create a memo which will display on 
                the dashboards of all employees 
                (and/or contractors) for a limited time period, and optionally get sent as an email as well.
            </p>
            
            <div class="form-outline"  id="text-area">
                <textarea class="form-control" formControlName="content" [ngClass]="{ 'is-invalid': submitted && announceForm.get('content')?.invalid}" placeholder="Please Enter Announcement Here" id="textAreaExample" rows="4"></textarea>
                <div *ngIf="submitted && announceForm.get('content')?.invalid" class="invalid-feedback">
                  <div *ngIf="announceForm.get('content')?.hasError('required')">Message is required</div>
              </div>
              </div>    
        
            <label id="label" class="form-label" for="formControlDefault">Recipient*</label>
            <div class="select">
                <select class="form-select" aria-label="Default select example" formControlName="sender" name="recipient" [ngClass]="{ 'is-invalid': submitted && announceForm.get('sender')?.invalid}" [(ngModel)]="selectedCategory" (ngModelChange)="onSelected($event)">
                    <option value="3342bcd62146d71e38fd859f">All Department</option>
                    <option value="2bcd62146d71e38fd859f">Lecturers</option>
                    <option value="6092bcd62146d71e38fd859f">As. lecturer</option>
                  </select>
                  <div *ngIf="submitted && announceForm.get('sender')?.invalid" class="invalid-feedback">
                    <div *ngIf="announceForm.get('sender')?.hasError('required')">Sender required</div>
                </div>
            </div>
        
            <div class="form-outline" id="input-field" style="width:70%;">
                <label class="form-label" for="formControlDefault">Show Announcement till*</label>
                <input type="date" formControlName="date" id="formControlDefault" class="form-control"/>
            </div>
            
        
            <div class="form-check" style="margin-top:7px">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label id="title-name" class="form-check-label" for="flexCheckDefault">
                  Also send Announcement on email
                </label>
            </div>
          </form>
          <div class="mid-tab">
              <button (click)="announce()" type="button" class="btn btn">Create</button>
          </div>
    </div>


    <div class="line">
        <hr>
    </div>
    <div class="active-memo">
        <div class="memo-title">Active Announcement</div>
        <div>
            <select class="form-memo" aria-label="Default select example">
                <option selected>Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
              </select>
        </div>
    </div>
    <div class="card">
      <div class="table-responsive">
        <table class="table">
            <thead style="background-color: #E6EEF6;">
              <tr style="color:#00356B">
                <th scope="col">Create On</th>
                <th scope="col">Expires On</th>
                <th scope="col">Memo Text</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let announcement of allAnnouncement">
                <th>{{ announcement.created_at | date }}</th>
                <td>07/08/2021</td>
                <td>{{ announcement.content}}</td>
                <td (click)="deleteAnnouncement(announcement)"><div><i style="font-size: 20px;padding-top:3px;cursor: pointer;" class="far fa-trash-alt"></i></div>
                </td>
              </tr>
    
            </tbody>
          </table>
      </div>
      </div>
</div>