import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filemanagement',
  templateUrl: './filemanagement.component.html',
  styleUrls: ['./filemanagement.component.scss']
})
export class FilemanagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
