<div class="container" style="padding-bottom:20px">
    <div class="row">
        <div class="col-md-3" style="padding-right:0px;">
            <div style="height:100% !important; width:auto; padding: 20px 0px;" class="card">
                <div style="display:flex; justify-content: space-around; border-bottom: 1px solid #A5A5A5;padding-bottom:20px">
                    <div style="display: flex;">
                        <i style="font-size:20px;color:#A5A5A5; margin-right:20px" class="fas fa-search"></i>
                        <div style="color:#A5A5A5; font-size:12px">Search</div>
                    </div>
                    <div>
                        <div style="font-size:20px;color:#A5A5A5;border: 1px solid #7A7A7A; padding:5px"><i class="fas fa-filter"></i></div>
                    </div>
                </div>

                <div style="padding:25px 15px; border-bottom: 1px solid #A5A5A5;">
                    <div style="display: flex;">
                        <div><img src="assets/images/profile-pic.jpg"></div>
                        <div class="msg" style="margin-left:10px">
                            <div style="display: flex;">
                                <div style="font-size: 12px; font-weight: bold;margin-right:30px; color:#00356B">Prof. Madu C.</div>
                                <div style="color:#A5A5A5;font-size: 10px;">2 days ago</div>
                            </div>
                            <div style="font-size: 10px;color:#A5A5A5;">Lecturer 1</div>
                            <div style="font-size: 10px;color:#A5A5A5;">Computer Department</div>
                        </div>
                    </div>
                </div>

                <div style="padding:25px 15px; border-bottom: 1px solid #A5A5A5;">
                    <div style="display: flex;">
                        <div><img src="assets/images/profile-pic.jpg"></div>
                        <div class="msg" style="margin-left:10px">
                            <div style="display: flex;">
                                <div style="font-size: 12px; font-weight: bold;margin-right:30px; color:#00356B">Prof. Madu C.</div>
                                <div style="color:#A5A5A5;font-size: 10px;">2 days ago</div>
                            </div>
                            <div style="font-size: 10px;color:#A5A5A5;">Lecturer 1</div>
                            <div style="font-size: 10px;color:#A5A5A5;">Computer Department</div>
                        </div>
                    </div>
                </div>

                <div style="padding:25px 15px; border-bottom: 1px solid #A5A5A5;">
                    <div style="display: flex;">
                        <div><img src="assets/images/profile-pic.jpg"></div>
                        <div class="msg" style="margin-left:10px">
                            <div style="display: flex;">
                                <div style="font-size: 12px; font-weight: bold;margin-right:30px; color:#00356B">Prof. Madu C.</div>
                                <div style="color:#A5A5A5;font-size: 10px;">2 days ago</div>
                            </div>
                            <div style="font-size: 10px;color:#A5A5A5;">Lecturer 1</div>
                            <div style="font-size: 10px;color:#A5A5A5;">Computer Department</div>
                        </div>
                    </div>
                </div>

                <div style="padding:25px 15px; border-bottom: 1px solid #A5A5A5;">
                    <div style="display: flex;">
                        <div><img src="assets/images/profile-pic.jpg"></div>
                        <div class="msg" style="margin-left:10px">
                            <div style="display: flex;">
                                <div style="font-size: 12px; font-weight: bold;margin-right:30px; color:#00356B">Prof. Madu C.</div>
                                <div style="color:#A5A5A5;font-size: 10px;">2 days ago</div>
                            </div>
                            <div style="font-size: 10px;color:#A5A5A5;">Lecturer 1</div>
                            <div style="font-size: 10px;color:#A5A5A5;">Computer Department</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-6" style="padding:0px; margin-top:1px">
            <div style="background-color: #fff; padding: 20px;">
                <div style="display: flex; justify-content: space-between;">
                    <div style="width: 25%;">
                        <!-- <select style="background-color: #B5861E; color:#fff" class="form-select form-select-sm" aria-label=".form-select-sm example" id="onSelect">
                            <option selected>New Mail</option>
                            <option value="1">One</option>
                            <option (click)="createMsg()">Two</option>
                            <option value="3">Three</option>
                          </select> -->
                          <div class="mid-tab">
                            <button (click)="createMsg()" type="button" class="btn btn">New Mail</button>
                        </div>
                    </div>

                    <div style="display: flex;">
                        <div>
                            <button style="border: 1px solid #7A7A7A; color:#00356B; font-size: 13px; font-weight:500" type="button" class="btn btn-outline" data-mdb-ripple-color="dark">
                                Received
                            </button>
                        </div>
                        <div style="padding:8px 30px">Sent</div>
                        <div style="padding:8px 30px">Draft</div>
                    </div>
                </div>
            </div>

            <div><app-mailmessage></app-mailmessage></div>

            <div><app-mailreply></app-mailreply></div>
        </div>




        <div class="col-md-3" style="padding-left: 0px;">
            <div style="height:100% !important; width:auto; padding: 20px 0px; text-align: center;" class="card">
                <div style=" margin-top: 30px;"><img src="assets/images/profile2.png"/></div>
                <div>
                    <h3 style="color:#00356B; font-weight:500">Prof. Madu Chiamaka</h3>
                    <div style="color:#7A7A7A">Lecture: Computer</div>
                    <div style="color:#7A7A7A">Science  Department</div>
                </div>

                <div style="border-bottom: 1px solid #A5A5A5; padding:30px">
                    <button style="border: 1px solid #7A7A7A; font-size: 13px;" type="button" class="btn btn-outline" data-mdb-ripple-color="dark">
                        View profile
                    </button>
                </div>

                <div style="margin-top:20px">
                    <div>
                        <div><i style="margin-right: 10px; color:#7A7A7A" class="far fa-envelope"></i>maduchi@gmail.com</div>
                    </div>
    
                    <div style="margin-top:10px">
                        <div><i style="margin-right:10px; color:#7A7A7A" class="fas fa-phone-alt"></i>09087653534</div>
                    </div>

                    <div style="margin-top:45px;color:#00356B; font-weight:550">Send Private Message</div>
                </div>

            </div>
        </div>
    </div>
</div>