import { Component, OnInit } from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-staffdetails',
  templateUrl: './staffdetails.component.html',
  styleUrls: ['./staffdetails.component.scss']
})
export class StaffdetailsComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  infoComp:boolean=true;
  educationComp:boolean=false;
  achievementComp:boolean=false;
  promotionComp:boolean=false;
  documentComp:boolean=false;
  salaryComp:boolean=false;
  bankdetailsComp:boolean=false;
  retirementComp:boolean=false;


  information(){
    this.infoComp=true;
    this.educationComp=false;
    this.achievementComp=false;
    this.promotionComp=false;
    this.documentComp=false; 
    this.salaryComp=false;
    this.bankdetailsComp=false;
    this.retirementComp=false;
  }
  
  
  education(){
    this.infoComp=false;
    this.educationComp=true;
    this.achievementComp=false;
    this.promotionComp=false;
    this.documentComp=false; 
    this.salaryComp=false;
    this.bankdetailsComp=false;
    this.retirementComp=false;
  }

  achievement(){
    this.infoComp=false;
    this.educationComp=false;
    this.achievementComp=true;
    this.promotionComp=false;
    this.documentComp=false; 
    this.salaryComp=false;
    this.bankdetailsComp=false;
    this.retirementComp=false;
  }

  promotion(){
    this.infoComp=false;
    this.educationComp=false;
    this.achievementComp=false;
    this.promotionComp=true;
    this.documentComp=false; 
    this.salaryComp=false;
    this.bankdetailsComp=false;
    this.retirementComp=false;
  }

  document(){
    this.infoComp=false;
    this.educationComp=false;
    this.achievementComp=false;
    this.promotionComp=false;
    this.documentComp=true; 
    this.salaryComp=false;
    this.bankdetailsComp=false;
    this.retirementComp=false;
  }

  salary(){
    this.infoComp=false;
    this.educationComp=false;
    this.achievementComp=false;
    this.promotionComp=false;
    this.documentComp=false; 
    this.salaryComp=true;
    this.bankdetailsComp=false;
    this.retirementComp=false;
  }

  bankdetails(){
    this.infoComp=false;
    this.educationComp=false;
    this.achievementComp=false;
    this.promotionComp=false;
    this.documentComp=false; 
    this.salaryComp=false;
    this.bankdetailsComp=true;
    this.retirementComp=false;
  }

  retirement(){
    this.infoComp=false;
    this.educationComp=false;
    this.achievementComp=false;
    this.promotionComp=false;
    this.documentComp=false; 
    this.salaryComp=false;
    this.bankdetailsComp=false;
    this.retirementComp=true;
  }

  home(){
    this.router.navigate(['dashboard/hr-mgt'])
  }

}
