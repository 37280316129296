import { PositionmodalComponent } from './../positionmodal/positionmodal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  addPosition(){
    this.dialog.open(PositionmodalComponent, {height:'330px',width:'500px'});
  }

}
