import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngxs/store';
import { Logout } from '../../actions/auth.action';
import { AuthService } from '../../services/auth/auth.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.scss']
})
export class DashboardNavbarComponent implements OnInit {

  constructor(
    private navService: NavigationService,
    private _auth: AuthService,
    private _store: Store,
    ) { }

  ngOnInit(): void {
  }

  toggleSideNav() {
    this.navService.setShowNav(true);
  }
  
  LogOut(){
    this._store.dispatch(new Logout())
    .subscribe(Response => {
      console.log(Response)
      location.reload()
    })
  }
}
