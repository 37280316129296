<div class="container">

    <section id="top-row">
        <div class="row">
            <div class="budget-holder" >
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls">  
        <div class="row">
            <div class="col-md-3">
                <input style="padding: 6px 2px ; padding-left:10px; border:none" placeholder="Search"  type="text">
            </div>
            <div class="col-md-2" style="margin-right: 10px;">
               <input style="padding: 6px 2px; border: none;" type="date">            
            </div>
            <div class="col-md-2" style="margin-right: 10px;">
                <input style="padding: 6px 2px; border: none;" type="date">   
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example">
                    <option value="2">Import</option>
                    <option value="3">Export</option>
                </select>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table" style="color:#00356B;">
                <thead style="font-weight: lighter !important;">
                  <tr style="color:#00356B; font-weight:600">
                    <td scope="col">ID</td>
                    <td scope="col">Staff Name</td>
                    <td scope="col">Destination</td>
                    <td scope="col">Departure</td>
                    <td scope="col">Arival</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">clement john</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>Mark</td>
                    <td><button style="font-size:13px; color:#fff; background-color:#B5861E" type="button" class="btn btn">Approve</button>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">clement john</td>
                    <td>Jacob clement</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>Mark</td>
                    <td><button style="font-size:13px; color:#fff; background-color:#D97070" type="button" class="btn btn">Rejected</button>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">clement john</td>
                    <td>Jacob clement</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>Mark</td>
                    <td><button style="font-size:13px; color:#fff; background-color:#00356B" type="button" class="btn btn">Approved</button>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>

    </div>

</div>    