<div class="container" style="padding-top:30px">
   
    <div class="card" style="padding:10px; margin-bottom:17px; cursor:pointer">
        <div class="controller" style="display:flex; justify-content: space-around;  color: #C4C4C4; font-weight:500">
            <div id="common-controller" (click)="stock()" [ngClass]="{'active': stockComp}">Stock & inventory</div> 
            <div id="common-controller" (click)="work()" [ngClass]="{'active': workComp}">Work Report</div>
            <div id="common-controller" (click)="event()" [ngClass]="{'active': eventComp}">Event/Activities</div> 
            <!-- <div (click)="trip()" [ngClass]="{'active': tripComp}">Trip</div>
            <div (click)="leave()" [ngClass]="{'active': leaveComp}">Leave</div> -->
            <div id="common-controller" (click)="request()" [ngClass]="{'active': requestComp}">Requests</div>
            <div id="common-controller" (click)="budget()" [ngClass]="{'active':  budgetComp}">Budgets</div>
        </div>
    </div>
</div>


<div *ngIf="workComp" >
    <app-report></app-report>
</div>
<div *ngIf="stockComp">
    <app-stock></app-stock>
</div>
<div *ngIf="eventComp">
    <app-events></app-events>
</div>
<!-- <div *ngIf="tripComp">
    <app-trip></app-trip>
</div> -->
<!-- <div *ngIf="leaveComp">
    <app-leave></app-leave>
</div> -->
<div *ngIf="requestComp">
    <app-request></app-request>
</div>
<div *ngIf="budgetComp">
    <app-budget></app-budget>
</div>