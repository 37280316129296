import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { AddTime } from 'src/app/shared/actions/hr-mgt/time.action';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-set-time-limit-modal',
  templateUrl: './set-time-limit-modal.component.html',
  styleUrls: ['./set-time-limit-modal.component.scss']
})
export class SetTimeLimitModalComponent implements OnInit {

  createTime!: FormGroup

  constructor(private fb: FormBuilder,
    private store:Store,
    public dialogRef: MatDialogRef<SetTimeLimitModalComponent>
    ) { }

  ngOnInit(): void {
    this.createTime = this.fb.group({
      early_time: ['', Validators.compose([Validators.required])],
      dismisal_time: ['', Validators.compose([Validators.required])],
    })
  }

  addTime(){
    this.store.dispatch(new AddTime(this.createTime.value))
    this.dialogRef.close()
  }

}
