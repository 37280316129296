import { DeleteBonus } from './../../../shared/actions/hr-mgt/bonus.action';
import { Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewbenfitsComponent } from '../newbenfits/newbenfits.component';
import { GetBonus } from 'src/app/shared/actions/hr-mgt/bonus.action';

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss']
})
export class BonusComponent implements OnInit {

  title = "Bonus & Benefits"
  bonuses$ : any;
  allBonuses : any

  constructor(
    private store: Store,
    private dialog : MatDialog
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new (GetBonus))
    this.bonuses$ = this.store.select(state => state.bonuses.bonuses).subscribe(data =>{
      this.allBonuses = data
      console.log(this.allBonuses)
    });
  }


  addBenefit(){
    this.dialog.open(NewbenfitsComponent, {height:'450px',width:'500px'});
  }

    // delete bonus
    deleteBenefit(bonus: any){
      this.store.dispatch(new DeleteBonus(bonus._id));
    }

}
