import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ProfilemodalComponent } from '../profilemodal/profilemodal.component';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit {

  title="Profile"

  constructor(
    public dialog: MatDialog,
  ) { }


  ngOnInit(): void {
  }


  edit(){
    this.dialog.open(ProfilemodalComponent, {height:'500px',width:'500px'});
  }

}
