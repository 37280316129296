<div class="container" style="padding-bottom: 30px;">
    <section id="mid-inputs-controls" style="margin-bottom:10px; justify-content: space-between !important;">  
        <div class="row">
            <div class="col-md-6">
                <div style="font-weight: 700; color:#00356B; font-size: 20px;">Position</div>
            </div>
         
           
            <div class="col-md-6" style="text-align: right !important;">
                <button (click)="addPosition()" style="font-size:13px; color:#fff; background-color:#00356B" class="btn btn">Add Position</button>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table" style="color:#00356B;">
                <thead style="background-color: #00356B; color:#fff">
                  <tr style="font-weight:500">
                    <td scope="col">Position</td>
                    <td scope="col">Assign role</td>
                    <td scope="col">Date Created</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">1</td>
                    <td>Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>
                      <div class="edit-btn" style="margin-right:10px; display: flex;">  
                        <button type="button" style="color:#00356B;font-size: 12px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                        <div><i style="font-size: 20px;padding-top:3px; cursor: pointer;" class="far fa-trash-alt"></i></div>
                    </div>
                    </td>

                  </tr>
                  <tr>
                    <td scope="row">2</td>
                    <td scope="row">Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>
                      <div class="edit-btn" style="margin-right:10px; display: flex;">  
                        <button type="button" style="color:#00356B;font-size: 12px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                        <div><i style="font-size: 20px;padding-top:3px;cursor: pointer;" class="far fa-trash-alt"></i></div>
                    </div>
                    </td>
                   
                  </tr>
                  <tr>
                    <td scope="row">3</td>
                    <td scope="row">Chukwudera Kenneth</td>
                    <td>ICT</td>
                    <td>
                        <div class="edit-btn"  style="margin-right:10px; display: flex;">  
                            <button type="button" style="color:#00356B;font-size: 12px; margin-right:13px" class="btn btn-outline-dark btn-sm">Edit</button>
                            <div><i style="font-size: 20px;padding-top:3px; cursor: pointer;" class="far fa-trash-alt"></i></div>
                        </div>
                    </td>
                   
                  </tr>
                </tbody>
              </table>
        </div>
      </div>
</div>