import Swal from 'sweetalert2'
import { Store } from '@ngxs/store';
import { AddStock } from './../../../../shared/actions/stock.action';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-stock-modal',
  templateUrl: './add-stock-modal.component.html',
  styleUrls: ['./add-stock-modal.component.scss']
})
export class AddStockModalComponent implements OnInit {

  createStock!: FormGroup
  submitted = false

  constructor(
    public fb: FormBuilder,
    public store: Store,
    public dialogRef: MatDialogRef<AddStockModalComponent>


  ) { }

  ngOnInit(): void {
    this.createStock = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      quantity: ['', Validators.compose([Validators.required])],
      remark: ['']
    })

  }


  inventory(){
    this.submitted = true
    if(this.createStock.invalid){
      return;
    }else{
      this.store.dispatch(new AddStock(this.createStock.value))
      this.dialogRef.close();          
    }
  }

}
