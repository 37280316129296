import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
let Url = ""
if (isDevMode()) {
  Url = environment.apiUrl;
} else {
  console.log('💪 Production!');
}

@Injectable({
  providedIn: 'root'
})


export class AuthService {
  APIUrl =  Url
  constructor(
    private http: HttpClient,
    private _router: Router,
    
    ) { }
    
  UserLogin (User: any){
    return this.http.post<any>(this.APIUrl, User)
  }


  getUser (){
    return this.http.get<any>(this.APIUrl + 'get-user')
  }
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('UserToken');
    return (user !== null) ? true : false;
  }

  // Sign out 
  // LogOut() {

  //     localStorage.removeItem('UserToken');
  //     this._router.navigate(['/transcript']);
    
  // }

  LogOut(): Observable<null> {
    return of(null);
}

  getToken(){
    return localStorage.getItem('UserToken');
  }
}
