<div mat-dialog-title style="display: flex; justify-content: space-between;border-bottom: 1px solid gray;">
    <div style="font-size: 20px; color:#00356B; font-weight: 500;">Name & Address</div>
</div>

<mat-dialog-content>

    <div style="width:100%">
        <div class="form-outline" style="margin-top:10px">
            <label class="form-label"  style="color:#00356B; font-weight:500">Company Name</label>
            <input type="text" class="form-control" />
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B; font-weight:500">Institution Email</label>
            <input type="text" class="form-control"/>
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B;font-weight:500">State</label>
            <input type="text" class="form-control" />
        </div>

        <div class="form-outline" style="margin-top:20px">
            <label class="form-label"  style="color:#00356B; font-weight:500;">Address</label>
            <input type="text" class="form-control" />
        </div>


       
        <div style="display:flex; margin-top:10px; justify-content: center!important; float:right !important">
            <div><button style="background-color: #FFF7F7; color:#7A7A7A; outline: none;margin-right:20px" mat-dialog-close type="button" class="btn btn-primary btn-md">Cancel</button></div>
            <div><button style="background-color:#00356B; color:#fff" type="button" class="btn btn btn-md">Save</button></div>
        </div>
    </div>

</mat-dialog-content>