import { GetRequest, AddRequest } from './../actions/request.actions';
import { OperationService } from './../services/operation/operation.service';
import { Requests } from './../models/operations.model';
import {State, Action, StateContext, Selector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import Swal from 'sweetalert2'



export class RequestStateModel {
    requests!: Request[];
}

@State<RequestStateModel>({
    name: 'requests',
    defaults: {
        requests: [],
    }
})

@Injectable()
export class RequestState {

    constructor(private operationService: OperationService) {
    }

    @Selector()
    static GetRequests(state: RequestStateModel) {
        return state.requests;
    }


    @Action(GetRequest)
    GetRequest({getState, setState}: StateContext<RequestStateModel>) {
        return this.operationService.getRequests().pipe(tap((result) => {
            console.log(result)
            const state = getState();
            setState({
                ...state,
                requests: result.data,
            });
        }));
    }
}
