import { UpdatemodalComponent } from './../departmentmodal/updatemodal/updatemodal.component';
import { GetDepartment, DeleteDepartment } from './../../../shared/actions/settings/depatments.action';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { DepartmentmodalComponent } from '../departmentmodal/departmentmodal.component';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

  departments : any
  allDepartments: any

  constructor(
    private dialog: MatDialog,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new (GetDepartment))
    this.departments = this.store.select(state => state.departments.departments).subscribe(data =>{
      this.allDepartments = data
    });
  }

  addDepartment(){
    this.dialog.open(DepartmentmodalComponent, {height:'430px',width:'500px'});
  }

  editDepartment(department:any){
    this.dialog.open(UpdatemodalComponent, {height:'430px',width:'500px'});
    localStorage.setItem("facultyID", department._id)
    console.log(department._id)
  }

  deleteFaculty(department:any){
    this.store.dispatch(new DeleteDepartment(department._id));
  }

}
