import { Requests } from './../models/operations.model';


export class AddRequest {
    static readonly type = '[Request] Add';

    constructor(public payload: Requests) {
    }
}

export class GetRequest {
    static readonly type = '[Request] Get';
}
