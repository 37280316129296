import { MainPageHeaderComponent } from './../../shared/component/main-page-header/main-page-header.component';
import { MainPagesComponent } from './main-pages.component';


import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { RouterModule } from '@angular/router';

import { MainPageFooterComponent } from 'src/app/shared/component/main-page-footer/main-page-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from 'src/app/main-pages/home/home.component';



@NgModule({
  declarations: [
    MainPagesComponent,
    HomeComponent,
    MainPageHeaderComponent,
    MainPageFooterComponent
    



  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class MainPagesModule { }
