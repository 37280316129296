<div class="container">
    <section id="top-row">
        <div class="row">
            <div class="budget-holder" >
                <span>Operations/{{ title }}</span>
            </div>
        </div>
    </section>

    <section id="mid-inputs-controls">  
        <div class="row">
            <div class="col-md-3">
                <input id="input-field" placeholder="Search"  type="text">
            </div>
            <div class="col-md-2" style="margin-right: 10px;">
               <input id="input-field" type="date">            
            </div>
            <div class="col-md-2" style="margin-right: 10px;">
                <input id="input-field" type="date">   
            </div>
            <div class="col-md-2">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="col-md-2" id="a-select">
                <select class="form-select" aria-label="Default select example" id="mySelect">
                    <option value="2">Import</option>
                    <option value="3">Export</option>
                </select>
            </div>
        </div>
    </section>

    <div class="card">
        <div class="table-responsive">
            <table class="table" style="color:#00356B;">
                <thead style="font-weight: lighter !important;">
                  <tr style="color:#00356B; font-weight:500">
                    <td scope="col">Name</td>
                    <td scope="col">Department</td>
                    <td scope="col">Date</td>
                    <td scope="col">Category</td>
                    <td scope="col">Description</td>
                    <td scope="col">View</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="allReports.length">
                        <tr *ngFor="let report of allReports">
                          <td scope="row">{{ report.name }}</td>
                          <td>{{ report.department }}</td>
                          <td>{{report.date}}</td>
                          <td>{{report.category}}</td>
                          <td>{{ report.description }}</td>
                          <td><button style="background-color: #fff; border: 1px solid rgb(194, 187, 187);"><i style="color:#B5861E" class="fas fa-eye"></i></button></td>
                          <td><button style="font-size:13px; color:#fff; background-color:#B5861E" type="button" class="btn btn">Approve</button>
                          </td>
                        </tr>
                    </ng-container>
                </tbody>
              </table>
          </div>
          <div>
            <ngx-skeleton-loader [theme]="{'height.px': 50}" count="10" appearance="line"  *ngIf="!allReports.length"></ngx-skeleton-loader>
        </div>
    </div>

</div>    
